import FormTextField, { FormTextFieldProps } from "./FormTextField";

export const BASE_58 =
  "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";

export function WalletAddressField(props: FormTextFieldProps) {
  const additionalRules = {
    pattern: {
      value: new RegExp(`^[${BASE_58}]*$`),
      message: `Invalid wallet address. Must consist of Base58 characters (${BASE_58}).`,
    },
  };
  return (
    <FormTextField
      {...props}
      name={"walletAddress"}
      label={"Wallet Address"}
      additionalRules={additionalRules}
    />
  );
}
