import { MembershipType } from "./membershipType";
import { ImageDto } from "./image";
import { propertiesOf } from "../utils/propertyOf";

export interface CouponType {
  id: string;
  branchId: number;
  name: string;
  description?: string;
  image: ImageDto | null;
  createdAt: string;
  validUntil?: string;
  externalUrl?: string;
}

export const defaultCouponType: CouponType = {
  id: "",
  branchId: 0,
  name: "",
  externalUrl: "",
  description: "",
  image: null,
  validUntil: "",
  createdAt: "",
};

export interface CouponRule {
  id: string;
  membershipType: MembershipType;
  couponType: CouponType;
  createdAt: string;
  active: boolean;
  airdropCount: number;
}

export interface CreateCouponRule {
  membershipTypeId: number | null;
  couponTypeId: number | null;
}

export const defaultCouponRule: CreateCouponRule = {
  membershipTypeId: null,
  couponTypeId: null,
};

export const ofCouponType = propertiesOf<CouponType>();
