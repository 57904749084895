import { HttpError, useList, useParsed } from "@refinedev/core";
import UnexpectedError from "pages/UnexpectedError";
import { createContext, ReactNode, useContext } from "react";
import { Branch } from "types/branch";

export const BranchContext = createContext<BranchContextType2>({} as any);

export interface BranchContextType2 {
  branch?: Branch;
  branches: Branch[];
}

export const BranchProvider = (props: { children: ReactNode }) => {
  const { params } = useParsed<{ branchId: string }>();
  const branchId = Number(params?.branchId || 0);
  const { data, isLoading } = useList<Branch, HttpError>({
    resource: "branches",
    pagination: {
      pageSize: 10000,
    },
  });
  const currentBranch = data?.data.find((branch) => branch.id === branchId);
  if (isLoading) return <UnexpectedError loading={isLoading} />;

  return (
    <BranchContext.Provider
      value={{
        branch: currentBranch,
        branches: data?.data || [],
      }}
    >
      {props.children}
    </BranchContext.Provider>
  );
};

export function useBranchContext(): BranchContextType2 {
  return useContext(BranchContext);
}

export function useBranch(): Branch | undefined {
  return useContext(BranchContext).branch;
}

export function useBranches(): Branch[] {
  return useContext(BranchContext).branches;
}
