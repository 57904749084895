import FormTextField from "../../components/form/FormTextField";
import { ofMember } from "../../types/member";
import { WalletAddressField } from "../../components/form/WalletAddressField";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useBranch } from "../../provider/BranchProvider";

export function MemberForm(props: {
  mayChangeWalletAddress?: boolean;
  disabled?: boolean;
}) {
  const { mayChangeWalletAddress = false, disabled = false } = props;
  const methods = useFormContext();
  const branch = useBranch();

  return (
    <>
      <WalletAddressField
        name={ofMember("walletAddress")}
        control={methods.control}
        disabled={disabled || !mayChangeWalletAddress}
        required={true}
      />
      <FormTextField
        name={ofMember("email")}
        control={methods.control}
        label={"Email"}
        additionalRules={{
          pattern: {
            value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            message: "Invalid Email",
          },
        }}
        disabled={disabled}
        required={branch?.personalDataRequired}
      />
      <FormTextField
        name={ofMember("name")}
        control={methods.control}
        label={"Name"}
        disabled={disabled}
        required={branch?.personalDataRequired}
      />
      <FormTextField
        name={ofMember("company")}
        control={methods.control}
        label={"Company"}
        disabled={disabled}
        required={branch?.personalDataRequired}
      />
    </>
  );
}
