import { useModal } from "@refinedev/core";
import { IOSSwitch } from "components/layout/IOSSwitch";
import { CouponRule } from "../../../types/couponType";
import CouponRuleDistributionDialog from "./CouponRuleDistributionDialog";

export default function UpdateCouponRuleDistirbutionDialog(props: {
  couponRule: CouponRule;
}) {
  const { couponRule } = props;
  const { visible, show, close } = useModal();
  return (
    <>
      <IOSSwitch checked={couponRule.active} onClick={show} />
      <CouponRuleDistributionDialog
        open={visible}
        onClose={close}
        couponRule={couponRule}
      />
    </>
  );
}
