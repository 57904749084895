import { HttpError } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { FormProvider } from "react-hook-form";
import { Coin, defaultCoin } from "../../types/coin";
import React from "react";
import { CoinForm } from "./CoinForm";
import { Typography } from "@mui/material";

export function EditCoin(props: { id: number }) {
  const methods = useForm<Coin, HttpError>({
    defaultValues: defaultCoin,
    refineCoreProps: {
      resource: "coins",
      id: props.id,
      action: "edit",
    },
  });
  return (
    <Edit
      goBack={null}
      headerButtons={() => null}
      saveButtonProps={methods.saveButtonProps}
    >
      <Typography variant={"subtitle1"} sx={{ mb: 3, mt: -3 }}>
        This is the currency of your branch.
      </Typography>
      <FormProvider {...methods}>
        <CoinForm />
      </FormProvider>
    </Edit>
  );
}
