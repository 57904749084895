import { MembershipType } from "../../types/membershipType";
import React from "react";
import { Stack } from "@mui/material";

export function MembershipTypeCell(props: {
  membershipType: MembershipType;
  mintAddress: string;
}) {
  return (
    <Stack direction="row" gap={1}>
      <img
        style={{ height: 50 }}
        src={props.membershipType.image?.url}
        alt="membership-nft"
      />
      {props.membershipType.name}
    </Stack>
  );
}
