import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useParsed } from "@refinedev/core";
import { CreateButton, SaveButton } from "@refinedev/mui";
import { useModalForm } from "@refinedev/react-hook-form";
import SelectMembership from "../../../components/form/SelectMembership";
import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { CouponRule, defaultCouponRule } from "../../../types/couponType";
import CouponRuleDistributionDialog from "./CouponRuleDistributionDialog";

export default function CreateCouponRuleDialog() {
  const { id: couponTypeId } = useParsed();
  const [openDistributionDialog, setOpenDistributionDialog] = useState(false);
  const [createdCouponRule, setCreatedCouponRule] = useState<CouponRule>();

  const methods = useModalForm<CouponRule>({
    refineCoreProps: {
      action: "create",
      resource: "coupon-rules",
    },
    defaultValues: defaultCouponRule,
  });

  const {
    control,
    handleSubmit,
    modal,
    reset,
    refineCore: { onFinish },
  } = methods;

  const handleClose = () => {
    reset();
    modal.close();
  };

  const [pending, setPending] = useState(false);

  const handleSave = async (values: FieldValues) => {
    setPending(true);
    await onFinish({
      ...values,
      couponTypeId: couponTypeId,
    }).then((res) => res && setCreatedCouponRule(res.data));
    setPending(false);
    handleClose();
    setOpenDistributionDialog(true);
  };

  return (
    <>
      <CreateButton onClick={() => modal.show()}>Create Rule</CreateButton>
      <Dialog open={modal.visible} onClose={handleClose} fullWidth>
        <form onSubmit={handleSubmit(handleSave)}>
          <DialogTitle>Choose Membership you want to Airdrop to</DialogTitle>
          <DialogContent>
            <Grid container flexDirection={"column"} spacing={2}>
              <Grid item>
                <SelectMembership
                  name="membershipTypeId"
                  control={control}
                  includeBlockchain={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={pending}>
              Cancel
            </Button>
            <SaveButton variant="contained" type="submit" />
          </DialogActions>
        </form>
      </Dialog>
      {createdCouponRule && (
        <CouponRuleDistributionDialog
          open={openDistributionDialog}
          onClose={() => {
            setOpenDistributionDialog(false);
            setCreatedCouponRule(undefined);
          }}
          couponRule={createdCouponRule}
          recentlyCreated
        />
      )}
    </>
  );
}
