import { GridProps } from "@mui/material/Grid/Grid";
import React, { ReactNode } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigation } from "@refinedev/core";

export function PureNumberDashboardCard(
  props: {
    gridProps: Partial<GridProps>;
    title: ReactNode;
    icon: ReactNode;
    resource: string;
  } & React.PropsWithChildren<{}>
) {
  const navigate = useNavigation();

  return (
    <Grid item {...props.gridProps}>
      <Box
        sx={{ background: "white", cursor: "pointer" }}
        padding={2}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        borderRadius={2}
        height={"100%"}
        onClick={() => navigate.list(props.resource)}
      >
        <Box width={"100%"} display={"flex"} flexDirection={"row"}>
          <Typography
            variant={"subtitle1"}
            textAlign={"left"}
            color={"grey"}
            width={"100%"}
            marginBottom={1}
          >
            {props.title}
          </Typography>
          <Box>{props.icon}</Box>
        </Box>
        {props.children}
      </Box>
    </Grid>
  );
}
