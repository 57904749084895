import { BaseRecord, HttpError } from "@refinedev/core";
import { FieldValues } from "react-hook-form";
import { UseFormReturnType } from "@refinedev/react-hook-form/src/useForm";
import React from "react";
import { useBranch } from "../../provider/BranchProvider";

export type SaveButtonProps = {
  disabled?: boolean;
  onClick?: (e: React.BaseSyntheticEvent) => void;
};

export function useSetBranchIdOnSubmit<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables extends FieldValues = FieldValues,
  TContext extends object = object
>(
  props: UseFormReturnType<TData, TError, TVariables, TContext>
): SaveButtonProps {
  const branch = useBranch();

  function setBranchIdOnFinish(values: TVariables) {
    return props.refineCore.onFinish({ ...values, branchId: branch?.id });
  }

  const saveButtonProps = {
    ...props.saveButtonProps,
    onClick: props.handleSubmit(setBranchIdOnFinish),
  };
  return saveButtonProps;
}
