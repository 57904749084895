import { propertiesOf } from "../utils/propertyOf";
import { Event } from "./event";
import { ImageDto } from "./image";
import { defaultWallet, WalletDto } from "./walletDto";

export enum TransactionStatus {
  OPEN = "OPEN",
  SUCCEEDED = "SUCCEEDED",
  EXPIRED = "EXPIRED",
}

export interface Transfer {
  id: number;
  mintAddress: string;
  amount: number;
}

export const ofTransactions = propertiesOf<Transaction>();

export interface Transaction {
  id: number;
  event: Event | null;
  target: WalletDto;
  source: WalletDto;
  createdAt: string;
  transfers: TransactionTransfer[];
  type: string;
}

export const defaultTransaction: Transaction = {
  id: 0,
  event: null,
  target: defaultWallet,
  source: defaultWallet,
  createdAt: "",
  transfers: [],
  type: "",
};

export interface TransactionTransfer {
  name: string;
  image: ImageDto | null;
  amount: number;
}

export const ofTransactionTransfers = propertiesOf<TransactionTransfer>();
