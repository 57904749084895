import { Box, Typography } from "@mui/material";
import FormTextField from "components/form/FormTextField";
import React from "react";
import { useFormContext } from "react-hook-form";

export function ExternalLinkSection() {
  const { control } = useFormContext();

  return (
    <div>
      <Typography variant="h6">Links</Typography>
      <FormTextField
        name="externalLink"
        control={control}
        label={"External Link"}
        fullWidth
        additionalRules={{
          pattern: {
            value: /^(https?|http):\/\/[^\s/$.?#].[^\s]*$/,
            message: "Invalid URL",
          },
        }}
      />
      <Box display="flex" flexDirection="row" gap={4}>
        <FormTextField
          name="socials.INSTAGRAM"
          control={control}
          label={"Instagram"}
          fullWidth
          additionalRules={{
            pattern: {
              value:
                /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+\/?$/,
              message: "Invalid Instagram URL",
            },
          }}
        />
        <FormTextField
          name="socials.FACEBOOK"
          control={control}
          label={"Facebook"}
          fullWidth
          additionalRules={{
            pattern: {
              value: /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.]+\/?$/,
              message: "Invalid Facebook URL",
            },
          }}
        />
      </Box>
      <Box display="flex" flexDirection="row" gap={4}>
        <FormTextField
          name="socials.X"
          control={control}
          label={"X"}
          fullWidth
          additionalRules={{
            pattern: {
              value: /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+\/?$/,
              message: "Invalid X URL",
            },
          }}
        />
        <FormTextField
          name="socials.TIKTOK"
          control={control}
          label={"TikTok"}
          fullWidth
          additionalRules={{
            pattern: {
              value: /^(https?:\/\/)?(www\.)?tiktok\.com\/@[\w.-]+\/?$/,
              message: "Invalid Tiktok URL",
            },
          }}
        />
      </Box>
    </div>
  );
}
