import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { FormProvider } from "react-hook-form";
import { useDefaultForm } from "../../components/form/useDefaultForm";
import { HttpError, useShow } from "@refinedev/core";
import { DataGrid } from "@mui/x-data-grid";
import {
  defaultTransaction,
  ofTransactions,
  Transaction,
} from "../../types/transaction";
import { transferCols } from "../../tables/transferCols";
import { WalletForm } from "../wallets/WalletForm";

export function TransactionDetails() {
  const methods = useDefaultForm<Transaction, HttpError>({
    defaultValues: defaultTransaction,
  });

  const transaction = useShow<Transaction, HttpError>({}).query.data?.data;

  const transfer = methods.watch(ofTransactions("transfers"));

  if (!transaction) return <div>Loading;</div>;

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid item md={6}>
          <Box display="flex" gap={3}>
            <TextField
              value={transaction.createdAt}
              label={"Timestamp"}
              variant="standard"
              sx={{ width: 300 }}
            />
            <TextField
              value={transaction.event ? transaction.event.title : "---"}
              label={"Event"}
              variant="standard"
              sx={{ width: 300 }}
            />
            <TextField
              value={transaction.type}
              label={"Type"}
              variant="standard"
              sx={{ width: 300 }}
            />
          </Box>
        </Grid>

        <Grid item md={6}>
          <Box display={"flex"} flexDirection={"column"} marginTop={4}>
            <WalletForm label={"source"} wallet={transaction.source} />
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginTop={4}>
            <WalletForm label={"target"} wallet={transaction.target} />
          </Box>
        </Grid>

        <Grid item md={6}>
          <Box display={"flex"} flexDirection={"column"} marginTop={4}>
            <Typography variant={"h6"}>Transfer</Typography>
            {transfer && (
              <DataGrid
                columns={transferCols}
                autoHeight
                rows={transfer}
                getRowId={(row) => row.name}
              />
            )}
          </Box>
        </Grid>
      </Box>
    </FormProvider>
  );
}
