import { HttpError } from "@refinedev/core";
import { Create, SaveButton } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { useBranch } from "../../provider/BranchProvider";
import React from "react";
import { FormProvider } from "react-hook-form";
import { Event, defaultEvent } from "types/event";
import BasicPage from "./form/pages/BasicPage";
import { getUtcEventDate } from "utils/eventUtils";

export default function CreateEvent() {
  const methods = useForm<Event, HttpError>({
    defaultValues: defaultEvent,
    mode: "onBlur",
    refineCoreProps: {
      redirect: "edit",
    },
  });
  const branch = useBranch();

  const {
    handleSubmit,
    refineCore: { onFinish, redirect },
  } = methods;

  const handleSave = async () => {
    await handleSubmit((values) => {
      onFinish({
        ...values,
        branchId: branch?.id,
        date: getUtcEventDate(values.date),
      }).then((event: any) => {
        redirect("edit", (event as any).data.id);
      });
    })();
  };

  return (
    <FormProvider {...methods}>
      <Create
        footerButtons={() => (
          <SaveButton onClick={() => handleSave()}>
            Save and continue
          </SaveButton>
        )}
      >
        <BasicPage />
      </Create>
    </FormProvider>
  );
}
