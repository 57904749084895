import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { FieldValues, FormProvider } from "react-hook-form";
import { CouponType, defaultCouponType } from "../../types/couponType";
import { useBranch } from "../../provider/BranchProvider";
import { CouponTypeForm } from "./CouponTypeForm";

export default function CreateCouponType() {
  const methods = useForm<CouponType>({
    defaultValues: defaultCouponType,
  });
  const branch = useBranch();
  const customHandleSubmit = (values: FieldValues) => {
    return methods.refineCore.onFinish({ ...values, branchId: branch?.id });
  };

  return (
    <Create
      saveButtonProps={{
        ...methods.saveButtonProps,
        onClick: methods.handleSubmit(customHandleSubmit),
      }}
    >
      <FormProvider {...methods}>
        <CouponTypeForm />
      </FormProvider>
    </Create>
  );
}
