import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { CrudFilters } from "@refinedev/core";
import { formatISO } from "date-fns";
import { SyntheticEvent, useState } from "react";

export default function EventFilter(props: {
  filters: CrudFilters;
  setFilters: ((filters: CrudFilters) => void) &
    ((setter: (prevFilters: CrudFilters) => CrudFilters) => void);
}) {
  const { setFilters } = props;

  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [search, setSearch] = useState("");
  const [drafts, setDrafts] = useState(false);
  const [published, setPublished] = useState(false);
  const [notExpired, setNotExpired] = useState(false);

  const resetFilters = () => {
    setDrafts(false);
    setPublished(false);
    setNotExpired(false);
    setFilters([]);
    setFromDate(undefined);
    setToDate(undefined);
    setSearch("");
  };

  const handleCheckboxChange =
    (
      setter: React.Dispatch<React.SetStateAction<boolean>>,
      filterField: string
    ) =>
    (event: SyntheticEvent<Element, Event>, checked: boolean) => {
      setter(checked);
      if (checked) {
        setFilters((filters) => [
          {
            field: filterField,
            value: checked,
            operator: "eq",
          },
          ...filters,
        ]);
      } else {
        setFilters((prevFilters) =>
          prevFilters.filter(
            (filter) => "field" in filter && filter.field !== filterField
          )
        );
      }
    };

  return (
    <Grid
      container
      display="flex"
      spacing={2}
      alignItems="flex-end"
      justifyContent="flex-start"
      marginBottom={1}
    >
      <Grid item>
        <TextField
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setFilters((filters) => [
              {
                field: "search",
                value: e.target.value,
                operator: "eq",
              },
              ...filters,
            ]);
          }}
          label="Search"
          variant="standard"
        />
      </Grid>
      <Grid item>
        <DatePicker
          label={"From Date"}
          format="dd/MM/yyyy"
          // @ts-ignore
          value={fromDate || null}
          onChange={(date: Date | null) => {
            if (date) {
              setFromDate(date);
              setFilters((filters) => [
                {
                  field: "fromDate",
                  value: formatISO(date),
                  operator: "eq",
                },
                ...filters,
              ]);
            }
          }}
          slotProps={{
            textField: {
              variant: "standard",
              inputProps: { readOnly: true },
              InputLabelProps: { shrink: true },
            },
          }}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label={"To Date"}
          format="dd/MM/yyyy"
          // @ts-ignore
          value={toDate || null}
          onChange={(date: Date | null) => {
            if (date) {
              setToDate(date);
              setFilters((filters) => [
                {
                  field: "toDate",
                  value: formatISO(date),
                  operator: "eq",
                },
                ...filters,
              ]);
            }
          }}
          slotProps={{
            textField: {
              variant: "standard",
              inputProps: { readOnly: true },
              InputLabelProps: { shrink: true },
            },
          }}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          checked={drafts}
          control={<Checkbox />}
          label="Drafts"
          onChange={handleCheckboxChange(setDrafts, "draft")}
        />
        <FormControlLabel
          checked={published}
          control={<Checkbox />}
          label="Published"
          onChange={handleCheckboxChange(setPublished, "published")}
        />
        <FormControlLabel
          checked={notExpired}
          control={<Checkbox />}
          label="Exclude Past Events"
          onChange={handleCheckboxChange(setNotExpired, "notExpired")}
        />
      </Grid>
      <Grid item>
        <Button onClick={resetFilters}>Reset Filter</Button>
      </Grid>
    </Grid>
  );
}
