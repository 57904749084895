import { propertiesOf } from "../utils/propertyOf";

export interface CreateBlockchainImport {
  branchId: number;
  creatorAddress: string;
}

export interface BlockchainImport {
  id: number;
  creatorAddress: string;
  createdAt: string;
  finishedAt: string;
}

export const defaultBlockchainImport: BlockchainImport = {
  id: 0,
  creatorAddress: "",
  createdAt: "",
  finishedAt: "",
};

export const ofBlockchainImport = propertiesOf<BlockchainImport>();

export const ofCreateBlockChainImport = propertiesOf<CreateBlockchainImport>();

export const defaultCreateBlockchainImport = (
  creatorAddress?: string
): CreateBlockchainImport => ({
  branchId: 0,
  creatorAddress: creatorAddress || "",
});
