import { AxiosError, AxiosResponse } from "axios";

// tslint:disable-next-line: no-any
// @typescript-eslint/no-explicit-any
export function handleSuccessResponse(response: AxiosResponse): Promise<any> {
  return Promise.resolve(response.data);
}

export function handleErrorResponse(
  error: AxiosError
): Promise<ValidationErrorResponse> {
  if (
    error.response &&
    (error.response.status === 400 || error.response.status === 422)
  ) {
    if (error.response.data) {
      return Promise.reject(error.response as ValidationErrorResponse);
    } else {
      throw new Error(
        "An unexpected error occured: " + JSON.stringify(error.response.data)
      );
    }
  } else {
    throw new Error(error.message);
  }
}

export function retrieveValidationErrors(error: any): ValidationErrors {
  const response = error.response;
  if (isValidationErrorResponse(response)) {
    return response.data;
  } else {
    throw error;
  }
}

export function tryRetrieveValidationErrors(
  error: any
): ValidationErrors | null {
  const response = error.response;
  if (isValidationErrorResponse(response)) {
    return response.data;
  } else {
    return null;
  }
}

export function isValidationErrorResponse(
  response: AxiosResponse
): response is ValidationErrorResponse {
  return response !== undefined && response.status === 422;
}

export function formatValidationErrors(errors: ValidationErrors): string {
  let message = "";
  if (errors.fieldErrors) {
    message =
      message +
      errors.fieldErrors.map(
        (error) =>
          tryFormatErrorUsingCode(error) ||
          `${error.field}: ${error.defaultMessage}`
      );
  }
  if (errors.globalErrors) {
    message =
      message +
      errors.globalErrors.map(
        (error) =>
          tryFormatErrorUsingCode(error) ||
          `${error.objectName}: ${error.defaultMessage}`
      );
  }
  return message;
}

export function tryFormatErrorUsingCode(
  error: ObjectError | FieldError
): string | null {
  if (error.code) {
    return error.defaultMessage;
  } else {
    return null;
  }
}

export interface FieldError {
  field: string;
  code: string;
  arguments: [string, string | number][];
  defaultMessage: string;
}

export interface ObjectError {
  objectName: string;
  code: string;
  arguments: [string, string | number][];
  defaultMessage: string;
}

export interface ValidationErrors {
  fieldErrors: FieldError[];
  globalErrors: ObjectError[];
}

export interface ValidationErrorResponse extends AxiosResponse {
  data: ValidationErrors;
}
