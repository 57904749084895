import { Grid, useTheme } from "@mui/material";
import { useBranch } from "../provider/BranchProvider";
import { isNumber } from "lodash";

export default function CoinAmount(props: {
  /** Token amount, in cents. */
  amount?: number | null;
}) {
  const branch = useBranch();
  const theme = useTheme();
  if (!branch?.coin) {
    return null;
  }
  return (
    <Grid display="flex" alignItems="center">
      {branch.coin.image && (
        <img
          src={branch.coin.image.url}
          alt="Coin"
          style={{
            width: theme.spacing(3),
            marginRight: theme.spacing(1),
          }}
        />
      )}
      <span style={{ alignSelf: "center" }}>
        {isNumber(props.amount) ? displayCoinAmount(props.amount) : "?"}
      </span>
    </Grid>
  );
}

export function displayCoinAmount(amount: number): string {
  return (amount / 100).toLocaleString("en-US");
}
