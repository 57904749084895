import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { MecoLogo } from "../components/svgs";

export const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const urlParams = new URLSearchParams(window.location.search);
  const toParam = urlParams.get("to");

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack alignItems="center">
        <MecoLogo
          sx={{
            width: 280,
            marginBottom: -6,
          }}
        />
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Montserrat",
            marginBottom: 3,
          }}
        >
          meco
        </Typography>
        <Button
          size="large"
          onClick={() =>
            loginWithRedirect({
              appState: {
                returnTo: toParam ? decodeURIComponent(toParam) : "/",
              },
            })
          }
          variant="outlined"
        >
          Sign in
        </Button>
      </Stack>
    </Box>
  );
};
