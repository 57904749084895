import { List } from "@refinedev/mui";
import { News } from "types/news";
import { DataGrid } from "@mui/x-data-grid";
import { newsCols } from "../../tables/newsCols";
import { useBranchDependentDataGrid } from "../../utils/hooks/useBranchDependentDataGrid";
import { Typography } from "@mui/material";

export default function NewsList() {
  const { dataGridProps } = useBranchDependentDataGrid<News>({});

  return (
    <List>
      <Typography variant={"subtitle1"} sx={{ mb: 3, mt: -3 }}>
        Members can view news after their publish date.
      </Typography>
      <DataGrid {...dataGridProps} columns={newsCols} autoHeight />
    </List>
  );
}
