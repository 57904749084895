import { useFormContext } from "react-hook-form";
import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import FormTextField from "components/form/FormTextField";
import { ofEvent } from "../../../types/event";
import { ImageSection } from "./ImageSection";

export function MediaSection() {
  const { control, register } = useFormContext();

  useEffect(() => {
    register("bannerImageId");
  }, [register]);

  return (
    <div>
      <Typography variant="h6">Media</Typography>
      <Box marginTop={1}>
        <ImageSection />
        <FormTextField
          name={ofEvent("video")}
          label="Youtube video URL"
          control={control}
          fullWidth
          helperText={'Link needs to be in embed format ("/embed/")'}
          additionalRules={{
            pattern: {
              value:
                /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/embed\/.+$/,
              message: "Invalid video URL",
            },
          }}
        />
      </Box>
    </div>
  );
}
