import { List } from "@refinedev/mui";
import { DataGrid } from "@mui/x-data-grid";
import { useBranchDependentDataGrid } from "../../utils/hooks/useBranchDependentDataGrid";
import { Member } from "../../types/member";
import { memberCols } from "../../tables/memberCols";
import { useBranch } from "../../provider/BranchProvider";
import { Grid, Typography } from "@mui/material";
import MemberFilter from "./MemberFilter";

export default function MemberList() {
  const branch = useBranch();
  const { dataGridProps, filters, setFilters } =
    useBranchDependentDataGrid<Member>({});

  return (
    <List>
      <Typography variant={"subtitle1"} sx={{ mb: 2, mt: -3 }}>
        Overview of Members with assets in your branch.
      </Typography>
      <Grid item>
        <MemberFilter setFilters={setFilters} filters={filters} />
      </Grid>
      <DataGrid
        {...dataGridProps}
        columns={memberCols({
          coin: branch?.coin,
          personalData: branch?.personalDataRequired,
        })}
        autoHeight
      />
    </List>
  );
}
