import { useLink } from "@refinedev/core";
import { PropsWithChildren } from "react";

export function MemberLink(
  props: { branchId: number; memberId: string | number } & PropsWithChildren
) {
  const Link = useLink();

  return (
    <Link to={`/branches/${props.branchId}/members/edit/${props.memberId}`}>
      {props.children}
    </Link>
  );
}
