import { Create } from "@refinedev/mui";
import { HttpError } from "@refinedev/core";
import { defaultMember, Member } from "../../types/member";
import { FormProvider } from "react-hook-form";
import React from "react";
import { Box, FormControl, InputLabel } from "@mui/material";
import { MemberForm } from "./MemberForm";
import { useDefaultForm } from "../../components/form/useDefaultForm";
import { useSetBranchIdOnSubmit } from "../../components/form/useSetBranchIdOnSubmit";
import SelectMembership from "../../components/form/SelectMembership";

export function CreateMember() {
  const methods = useDefaultForm<Member, HttpError>({
    defaultValues: defaultMember,
  });
  const saveButtonProps = useSetBranchIdOnSubmit(methods);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <FormControl variant="standard" required>
            <InputLabel>Select Membership Type</InputLabel>
            <SelectMembership
              name="membershipTypeId"
              control={methods.control}
            />
          </FormControl>
          <MemberForm mayChangeWalletAddress={true} />
        </Box>
      </FormProvider>
    </Create>
  );
}
