import { useList } from "@refinedev/core";
import { List } from "@refinedev/mui";
import { useBranch } from "../../provider/BranchProvider";
import { Coin } from "../../types/coin";
import React from "react";
import { EditCoin } from "./EditCoin";

export function CoinList() {
  const branch = useBranch();
  const list = useList<Coin>({
    filters: [{ field: "branchId", operator: "eq", value: branch?.id }],
  })?.data?.data;
  if (list && list.length > 0) {
    return <EditCoin id={list[0].id} />;
  }
  return (
    <List title="Coin">
      <p>No Coin defined.</p>
    </List>
  );
}
