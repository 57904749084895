import React from "react";
import { Box, TextField } from "@mui/material";
import { WalletDto } from "../../types/walletDto";

interface WalletFormProps {
  label: string;
  wallet: WalletDto;
}

export function WalletForm({ label, wallet }: WalletFormProps) {
  return (
    <>
      <Box display="flex" gap={3}>
        <TextField
          value={wallet.address}
          label={label}
          variant="standard"
          sx={{ width: 500 }}
        />
        <TextField
          value={wallet.email}
          label={"Email"}
          variant="standard"
          sx={{ width: 300 }}
        />
        <TextField
          value={wallet.name}
          variant="standard"
          label={"Name"}
          sx={{ width: 300 }}
        />
        <TextField
          value={wallet.company}
          label={"Company"}
          variant="standard"
          sx={{ width: 300 }}
        />
      </Box>
    </>
  );
}
