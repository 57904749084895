import { GridColDef } from "@mui/x-data-grid";
import { CouponAirdrop } from "../types/couponAirdrop";
import { MemberLink } from "../pages/members/MemberLink";

export function airdropCols(branchId: number): GridColDef<CouponAirdrop>[] {
  return [
    {
      field: "couponId",
      headerName: "Coupon ID",
      renderCell: function render({ row }) {
        return row.coupon.id;
      },
    },
    {
      field: "membershipTypeAddress",
      headerName: "Membership Type",
      renderCell: function render({ row }) {
        return row.couponRule.membershipType.name;
      },
    },
    {
      field: "walletAddress",
      headerName: "Wallet",
      flex: 1,
      renderCell: function render({ row }) {
        return (
          <MemberLink memberId={row.walletAddress} branchId={branchId}>
            {" "}
            {row.walletAddress}{" "}
          </MemberLink>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
      renderCell: function render({ row }) {
        return <span>{new Date(row.coupon.createdAt).toLocaleString()}</span>;
      },
    },
  ];
}
