import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { CreateButton, SaveButton } from "@refinedev/mui";
import { useModalForm } from "@refinedev/react-hook-form";
import FormTextField from "components/form/FormTextField";
import { useState } from "react";
import { FieldValues } from "react-hook-form";
import {
  CreateTicket,
  defaultCreateTicket,
  ofCreateTicket,
} from "../../../types/ticket";

export default function CreateTicketDialog(props: { ticketTypeId: string }) {
  const methods = useModalForm<CreateTicket>({
    refineCoreProps: {
      action: "create",
      resource: "tickets",
    },
    defaultValues: defaultCreateTicket,
  });

  const {
    control,
    handleSubmit,
    modal,
    reset,
    refineCore: { onFinish },
  } = methods;

  const handleClose = () => {
    reset();
    modal.close();
  };

  const [pending, setPending] = useState(false);

  const handleSave = async (values: FieldValues) => {
    setPending(true);
    await onFinish({
      ...values,
      [ofCreateTicket("ticketTypeId")]: props.ticketTypeId,
    });
    setPending(false);
    handleClose();
  };

  return (
    <>
      <MenuItem>
        <CreateButton size="small" variant="text" onClick={() => modal.show()}>
          Gift to Member
        </CreateButton>
      </MenuItem>
      <Dialog open={modal.visible} onClose={handleClose} fullWidth>
        <form onSubmit={handleSubmit(handleSave)}>
          <DialogTitle>Gift Ticket to Member</DialogTitle>
          <DialogContent>
            <Grid container flexDirection={"column"}>
              <Grid item>
                <FormTextField
                  required
                  control={control}
                  name={ofCreateTicket("receiverWalletAddress")}
                  label="Receiver Wallet Key"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={pending}>
              Cancel
            </Button>
            <SaveButton variant="contained" type="submit" loading={pending} />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
