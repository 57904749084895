import React from "react";
import { DashboardData } from "../../types/dashboardData";
import { DashboardContentValue } from "../../components/dashboard/DashboardContentValue";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { PureNumberDashboardCard } from "../../components/dashboard/PureNumberDashboardCard";

export function MemberCard(props: { data: DashboardData }) {
  const { data } = props;
  return (
    <PureNumberDashboardCard
      gridProps={{ xs: 12, md: data.coins ? 6 : 4 }}
      title={"Current Members"}
      icon={<SupervisedUserCircleIcon fontSize={"large"} color={"primary"} />}
      resource={"members"}
    >
      <DashboardContentValue value={data.members.memberCount} />
    </PureNumberDashboardCard>
  );
}
