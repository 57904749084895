import { CouponType, CouponRule } from "./couponType";
import { propertiesOf } from "../utils/propertyOf";

export interface CouponAirdrop {
  id: string;
  coupon: CouponType;
  couponRule: CouponRule;
  walletAddress: string;
}

export interface CreateCoupon {
  couponTypeId: string | null;
  receiverWalletAddress: string | null;
  amount: number;
}

export const defaultCreateCoupon: CreateCoupon = {
  couponTypeId: null,
  receiverWalletAddress: null,
  amount: 1,
};

export const ofCreateCoupon = propertiesOf<CreateCoupon>();
