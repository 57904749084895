import { propertiesOf } from "../utils/propertyOf";

export const TICKET_ASPECT_RATIO = 2.0 / 1.0;

export interface CreateTicket {
  ticketTypeId: string | null;
  receiverWalletAddress: string | null;
}

export const defaultCreateTicket: CreateTicket = {
  ticketTypeId: null,
  receiverWalletAddress: null,
};

export const ofCreateTicket = propertiesOf<CreateTicket>();
