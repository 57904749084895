import { Grid, Typography } from "@mui/material";
import FormCheckboxField from "components/form/FormCheckboxField";
import { useFormContext } from "react-hook-form";
import { ofEvent } from "types/event";
import { SettingsAndVisibilitySection } from "../SettingsAndVisibilitySection";

export default function PublishPage() {
  const { control } = useFormContext();

  return (
    <Grid container flexDirection={"column"} spacing={6}>
      <Grid item>
        <Typography variant="h6">Payment</Typography>
        <FormCheckboxField
          name={ofEvent("coinPaymentEnabled")}
          control={control}
          label={"Enable Payment"}
        />
      </Grid>
      <Grid item>
        <SettingsAndVisibilitySection />
      </Grid>
    </Grid>
  );
}
