import { propertiesOf } from "../utils/propertyOf";
import { CoinBalance } from "./coinBalance";
import { Membership } from "./membership";
import { CouponType } from "./couponType";
import { TicketType } from "./ticketType";

export interface Member {
  id?: number;
  walletAddress: string;
  name?: string;
  email?: string;
  company?: string;
  coinBalance?: CoinBalance;
  memberships: Membership[];
  coupons: CouponType[];
  tickets: TicketType[];
}

export const ofMember = propertiesOf<Member>();

export const defaultMember: Member = {
  walletAddress: "",
  memberships: [],
  coupons: [],
  tickets: [],
};
