import { Box } from "@mui/material";

export function ImageColumn(props: {
  imageUrl?: string;
  borderRadius?: number | string;
  maxWidth?: number | string;
}) {
  if (!props.imageUrl) return <></>;
  return (
    <Box
      component="img"
      src={props.imageUrl}
      sx={(theme) => ({
        verticalAlign: "middle",
        maxWidth: theme.spacing(props.maxWidth ?? 4),
        borderRadius: props.borderRadius ?? 0,
      })}
    />
  );
}
