import React from "react";
import { useCustom } from "@refinedev/core";
import { Box, Grid } from "@mui/material";
import { DashboardData } from "../../types/dashboardData";
import { useBranch } from "../../provider/BranchProvider";
import { MemberCard } from "./MemberCard";
import { EventsCard } from "./EventsCard";
import { NewsCard } from "./NewsCard";
import { CoinsCard } from "./CoinsCard";
import { MembershipsCard } from "./MembershipsCard";
import { RedemptionsCard } from "./RedemptionsCard";
import { CouponsCard } from "./CouponsCard";
import { MemberGrowthCard } from "./MemberGrowthCard";

export function DashboardPage() {
  const branch = useBranch();
  const { data, isLoading, isError } = useCustom<DashboardData>({
    url: "/api/dashboard",
    method: "get",
    config: { query: { branchId: branch?.id } },
    queryOptions: { enabled: !!branch },
  });

  if (isLoading || isError || !data) {
    return (
      <Box>
        <h1>Dashboard</h1>
        {isLoading && <p>Loading...</p>}
        {isError && <p>Error fetching data - please try again later</p>}
      </Box>
    );
  }

  return (
    <Box>
      <h1>Dashboard</h1>
      <Grid container spacing={3}>
        <MemberCard data={data.data} />
        <RedemptionsCard data={data.data} />
        <CouponsCard data={data.data} />
        <CoinsCard data={data.data} />
        <MemberGrowthCard data={data.data} />
        <MembershipsCard data={data.data} />
        <Grid item xs={12} />
        <NewsCard data={data.data} />
        <EventsCard data={data.data} />
      </Grid>
    </Box>
  );
}
