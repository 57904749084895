import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { FormProvider } from "react-hook-form";
import {
  defaultMembershipType,
  MembershipType,
} from "../../types/membershipType";
import { HttpError } from "@refinedev/core";
import React from "react";
import { useSetBranchIdOnSubmit } from "../../components/form/useSetBranchIdOnSubmit";
import { MembershipTypeForm } from "./MembershipTypeForm";

export default function CreateMembershipType() {
  const methods = useForm<MembershipType, HttpError>({
    defaultValues: defaultMembershipType,
  });
  const saveButtonProps = useSetBranchIdOnSubmit(methods);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <FormProvider {...methods}>
        <MembershipTypeForm />
      </FormProvider>
    </Create>
  );
}
