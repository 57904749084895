import { EntityRef } from "utils/rest/entities";
import { propertiesOf } from "../utils/propertyOf";
import { ImageDto } from "./image";

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.MembershipTypeDto] */
export interface MembershipType extends EntityRef {
  id: number;
  name: string;
  symbol: string;
  branchId: number;
  membershipKey: string;
  image: ImageDto | null;
  imageUrl: string | null;
  includedCoinAmount: number;
  membershipCount?: number; // Only exists in `DetailedMembershipTypeDto`
  description?: string;
  externalUrl?: string;
  blockchainImportId?: number | null;
}

export const defaultMembershipType: MembershipType = {
  id: 0,
  name: "",
  symbol: "",
  branchId: 0,
  membershipKey: "",
  image: null,
  imageUrl: null,
  includedCoinAmount: 0,
  membershipCount: 0,
  blockchainImportId: null,
};

export const ofMembershipType = propertiesOf<MembershipType>();
