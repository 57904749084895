import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { SaveButton } from "@refinedev/mui";
import FormTextField from "../form/FormTextField";
import { useModalForm } from "@refinedev/react-hook-form";
import {
  CoinTransfer,
  defaultCoinTransfer,
  ofCoinTransfer,
} from "../../types/coinTransfer";
import { FieldValues } from "react-hook-form";

export default function IssueCoinMenuItem(props: {
  resource: string;
  address: string;
  coinId: number;
}) {
  const [pending, setPending] = useState(false);

  const {
    control,
    handleSubmit,
    modal,
    reset,
    refineCore: { onFinish },
  } = useModalForm<CoinTransfer>({
    refineCoreProps: {
      action: "create",
      resource: props.resource,
    },
    defaultValues: defaultCoinTransfer,
  });

  const handleClose = () => {
    reset();
    modal.close();
  };

  const handleSave = async (values: FieldValues) => {
    setPending(true);
    await onFinish({
      ...values,
      [ofCoinTransfer("coinId")]: props.coinId,
      [ofCoinTransfer("targetAddress")]: props.address,
    });
    setPending(false);
    handleClose();
  };

  return (
    <>
      <MenuItem onClick={() => modal.show()}>
        <Button>Issue Coin</Button>
      </MenuItem>
      <Dialog open={modal.visible} onClose={handleClose} fullWidth>
        <form onSubmit={handleSubmit(handleSave)}>
          <DialogTitle id="coin-issue-dialog-title"></DialogTitle>
          <DialogContent>
            <Grid container flexDirection={"column"}>
              <Grid item>
                <TextField
                  required
                  disabled={true}
                  value={props.address}
                  variant="standard"
                  label="Receiver Wallet Key"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <FormTextField
                  control={control}
                  name={ofCoinTransfer("amount")}
                  label="Coin Amount"
                  onFocus={(event) => event.target.select()}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  additionalRules={{
                    min: 0,
                  }}
                  transform={{
                    input: (v: any) => {
                      if (!v) return v;
                      return v * 100;
                    },
                    output: (v: any) => {
                      if (!v) return v;
                      return v / 100;
                    },
                  }}
                  required={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={pending}>
              Cancel
            </Button>
            <SaveButton
              variant="contained"
              type="submit"
              loading={pending}
              children={"Issue Coin"}
            />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
