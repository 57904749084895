import React from "react";
import { DashboardContentValue } from "../../components/dashboard/DashboardContentValue";
import { DashboardData } from "../../types/dashboardData";
import RedeemIcon from "@mui/icons-material/Redeem";
import { PureNumberDashboardCard } from "../../components/dashboard/PureNumberDashboardCard";

export function RedemptionsCard(props: { data: DashboardData }) {
  const { data } = props;
  return (
    <PureNumberDashboardCard
      gridProps={{ xs: 12, md: data.coins ? 6 : 4 }}
      title={"Redemptions"}
      icon={<RedeemIcon fontSize={"large"} color={"primary"} />}
      resource={"redemptions"}
    >
      <DashboardContentValue value={data.redemptions.totalRedemptions} />
    </PureNumberDashboardCard>
  );
}
