import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { EditButton, SaveButton } from "@refinedev/mui";
import { useModalForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";
import { defaultEventTicketType, TicketType } from "../../../types/ticketType";
import { CurrencyAmountField } from "../../../components/form/CurrencyAmountField";

export default function EditTicketTypeDialog(props: {
  ticketTypeId: string;
  updateForm: (ticket: TicketType) => void;
}) {
  const { ticketTypeId } = props;

  const {
    control,
    handleSubmit,
    modal,
    reset,
    refineCore: { onFinish },
  } = useModalForm<TicketType>({
    refineCoreProps: {
      action: "edit",
      resource: "ticket-types",
      id: ticketTypeId,
    },
    defaultValues: defaultEventTicketType,
  });

  const handleClose = () => {
    reset();
    modal.close();
  };

  const handleSave = async (values: FieldValues) => {
    try {
      const ticket = await onFinish(values);
      ticket?.data && props.updateForm(ticket.data);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <MenuItem onClick={() => modal.show()}>
        <EditButton size="small" />
      </MenuItem>
      <Dialog open={modal.visible} onClose={handleClose} fullWidth>
        <form onSubmit={handleSubmit(handleSave)}>
          <DialogTitle>Edit Ticket</DialogTitle>
          <DialogContent>
            <Grid container flexDirection={"column"} spacing={4}>
              <Grid item flexDirection={"column"} display="flex">
                <CurrencyAmountField
                  control={control}
                  name={"price"}
                  label="Price"
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <SaveButton type="submit" />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
