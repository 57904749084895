import { GraphicalDashboardCard } from "../../components/dashboard/GraphicalDashboardCard";
import React from "react";
import { DashboardData } from "../../types/dashboardData";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

export function EventsCard(props: { data: DashboardData }) {
  const { data } = props;
  const series = [
    data.events.draftEvents,
    data.events.upcomingEvents,
    data.events.liveEvents,
  ];
  const options: ApexOptions = {
    chart: {},
    dataLabels: {
      formatter(value, { seriesIndex, dataPointIndex, w }): string | number {
        return series[seriesIndex].toString();
      },
    },
    series: series,
    labels: ["Draft", "Upcoming", "Live"],
    colors: ["#70707050", "#FD6A1C", "#4832BB"],
  };

  return (
    <GraphicalDashboardCard
      gridProps={{ xs: 12, md: 6 }}
      title={"Events"}
      resource={"events"}
    >
      <Chart
        options={options}
        series={options.series}
        type="donut"
        width={350}
      />
    </GraphicalDashboardCard>
  );
}
