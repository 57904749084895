import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import FormTextField from "../../components/form/FormTextField";
import { ofCoin } from "../../types/coin";
import FormImageField from "../../components/form/FormImageField";
import { ofMembershipType } from "../../types/membershipType";
import React from "react";

export function CoinForm() {
  const { control } = useFormContext();

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <FormTextField
        name={ofCoin("name")}
        control={control}
        fullWidth
        label="Name"
        required
      />
      <FormImageField
        name={ofMembershipType("image")}
        control={control}
        required
        aspect={1.0}
        format={"(1:1)"}
      />
    </Box>
  );
}
