import React from "react";
import { useBranch } from "../../provider/BranchProvider";
import { CoinBalance } from "../../types/coinBalance";

export function CoinBalanceForm(props: { coinBalance: CoinBalance }) {
  const branch = useBranch();
  const balance = props.coinBalance;

  return (
    <>
      {branch?.coin != null && (
        <>
          {branch?.coin.name}-Balance:{"\n"}
          {balance.balance / 100}
        </>
      )}
    </>
  );
}
