import { DataProvider } from "@refinedev/core";
import { AxiosInstance } from "axios";
import { stringify } from "query-string";
import { isPage, Page } from "utils/rest/pageable";

function toQueryString(params: Record<string, any>): string {
  return Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
}

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance
): DataProvider => {
  return {
    create: async ({ resource, variables }) => {
      const response = await httpClient.post(
        `${apiUrl}/${resource}`,
        variables
      );

      return { data: response.data };
    },
    deleteOne: async ({ resource, id }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      const response = await httpClient.delete<never>(url);
      return { data: response.data };
    },
    getList: async ({ resource, pagination, sorters, filters }) => {
      const query: Record<string, unknown> = {};

      if (filters) {
        for (const filter of filters) {
          if (filter.operator !== "or") {
            const f = filter as any;
            if (f.field === "q") {
              query["search"] = filter.value;
            } else {
              query[f.field] = filter.value;
            }
          }
        }
      }

      if (pagination !== undefined) {
        query["page"] = pagination.current ? pagination.current - 1 : 0;
        query["size"] = pagination?.pageSize;
      }

      if (sorters !== undefined && sorters.length > 0) {
        query["sort"] = `${sorters[0].field.replaceAll(",", ".")},${
          sorters[0].order
        }`;
      }

      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      const response = await httpClient.get<Page<never> | never[]>(url);

      if (isPage(response.data)) {
        return {
          data: response.data.content,
          total: response.data.totalElements,
        };
      } else {
        return {
          data: response.data,
          total: response.data.length,
        };
      }
    },
    getOne: async ({ resource, id, meta }) => {
      const additionalQuery =
        meta?.additionalParams && toQueryString(meta.additionalParams);
      const url = `${apiUrl}/${resource}/${id}${
        additionalQuery ? `?${additionalQuery}` : ""
      }`;
      const response = await httpClient.get<never>(url);
      return { data: response.data };
    },
    update: async ({ resource, id, variables }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      const response = await httpClient.put<never>(url, variables);
      return { data: response.data };
    },
    custom: async ({
      url,
      method,
      sort,
      filters,
      payload,
      query,
      headers,
      metaData,
    }) => {
      let requestUrl = `${url}?`;

      if (sort) {
        throw Error("Sort is (currently) not supported for custom requests");
      }

      if (filters) {
        throw Error("Filter is (currently) not supported for custom requests");
      }

      if (query) {
        requestUrl = `${requestUrl}${stringify(query)}`;
      }

      if (headers) {
        httpClient.defaults.headers = {
          ...httpClient.defaults.headers,
          ...headers,
        };
      }

      let response;
      switch (method) {
        case "put":
        case "post":
        case "patch":
          response = await httpClient[method](url, payload);
          break;
        case "delete":
          response = await httpClient.delete(url);
          break;
        default:
          response = await httpClient.get(requestUrl);
          break;
      }

      return { data: response.data as never };
    },
    getApiUrl: () => apiUrl,
  };
};
