import { Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";

export function DashboardContentValue(props: {
  value: number | ReactNode;
  label?: string;
}) {
  return (
    <Box display={"flex"} flexDirection={"row"} width={"100%"}>
      {props.label && (
        <Typography
          textAlign={"left"}
          color={"darkgrey"}
          fontWeight={500}
          fontSize={"2rem"}
          marginRight={1}
        >
          {props.label}
        </Typography>
      )}
      <Typography
        textAlign={"left"}
        color={"black"}
        fontWeight={500}
        fontSize={"2rem"}
      >
        {props.value}
      </Typography>
    </Box>
  );
}
