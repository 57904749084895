import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { Show } from "@refinedev/mui";
import { useState } from "react";
import AirdropList from "./AirdropList";
import CouponRuleList from "../rules/CouponRuleList";

export default function AirdropPage() {
  const [selectedTab, setSelectedTab] = useState<"airdrops" | "rules">("rules");

  return (
    <Show title={<Typography variant="h5">Coupon Airdrops</Typography>}>
      <Box sx={{ width: "100%", typography: "body1" }} marginTop={2}>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(_, value) => setSelectedTab(value)}
              aria-label=""
            >
              <Tab label={"Rules"} value="rules" />
              <Tab label={"Airdrops"} value="airdrops" />
            </TabList>
          </Box>
          <TabPanel value="rules">
            <CouponRuleList />
          </TabPanel>
          <TabPanel value="airdrops">
            <AirdropList />
          </TabPanel>
        </TabContext>
      </Box>
    </Show>
  );
}
