import { EntityRef } from "utils/rest/entities";
import { propertiesOf } from "../utils/propertyOf";
import { ImageDto } from "./image";

/** Mirrors key2-server's [com.kurzdigital.kd_memberships.server.dto.admin_api.coin.CoinDto] */
export interface Coin extends EntityRef {
  id: number;
  branchId: number;
  address: string;
  name: string;
  image: ImageDto | null;
  decimals: number;
}

export interface CreateCoin {
  branchId: number;
  name: string;
  image: ImageDto | null;
  decimals: number;
}

export const defaultCoin: Coin = {
  id: 0,
  branchId: 0,
  address: "",
  name: "",
  image: null,
  decimals: 2,
};

export const ofCoin = propertiesOf<Coin>();
