import { BaseRecord, HttpError } from "@refinedev/core";
import { FieldValues } from "react-hook-form";
import { UseFormReturnType } from "@refinedev/react-hook-form/src/useForm";
import { SaveButton } from "@refinedev/mui";

export function useSaveFooterButton<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables extends FieldValues = FieldValues,
  TContext extends object = object
>(props: UseFormReturnType<TData, TError, TVariables, TContext>) {
  const handleSave = async (e: React.BaseSyntheticEvent) => {
    await props.handleSubmit((values) => {
      props.refineCore.onFinish({
        ...values,
      });
    })(e);
  };

  return {
    footerButtons: () => (
      <SaveButton onClick={(e: React.BaseSyntheticEvent) => handleSave(e)} />
    ),
  };
}
