import {
  CircularProgress,
  Divider,
  Grid,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useAxios } from "provider/AxiosProvider";
import React, { useEffect, useState } from "react";
import { RpcInfoDto, ServerInfo, SolanaServiceInfo } from "types/info";
import { queryOne } from "utils/rest/restApi";

function CustomDivider() {
  return <Divider light={true} sx={{ my: 2 }} />;
}

// const lamportsToSol = (lamports: number) => lamports / 1000000000; // 1 SOL === 10^9 Lamports

const truncateGitHash = (hash: string) => hash.slice(0, 7);

export default function InfoPage() {
  const [solanaServiceInfo, setSolanaServiceInfo] = useState<
    SolanaServiceInfo | string
  >();
  const axios = useAxios();

  useEffect(() => {
    queryOne<SolanaServiceInfo>(axios, "solana-service-info").then(
      setSolanaServiceInfo,
      (error) => setSolanaServiceInfo("Error fetching Solana Service Info")
    );
  }, [axios]);

  return (
    <Paper sx={{ p: 2 }}>
      <GitInfos solanaServiceInfo={solanaServiceInfo} />
      <CustomDivider />
      {typeof solanaServiceInfo === "object" ? (
        <SolanaServiceInfos solanaServiceInfo={solanaServiceInfo} />
      ) : typeof solanaServiceInfo === "string" ? (
        solanaServiceInfo
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
}

function GitInfos(props: { solanaServiceInfo?: SolanaServiceInfo | string }) {
  const solanaServiceInfo = props.solanaServiceInfo;
  const [serverInfo, setServerInfo] = useState<ServerInfo>();

  const axios = useAxios();

  useEffect(() => {
    queryOne<ServerInfo>(axios, "actuator/info").then(setServerInfo);
  }, [axios]);
  return (
    <>
      <Typography variant="h6">Admin Web Version</Typography>
      <Grid container>
        {process.env.REACT_APP_GIT_TAG && (
          <CustomListItemText
            label="Tag"
            value={process.env.REACT_APP_GIT_TAG}
          />
        )}
        <CustomListItemText
          label="Commit"
          value={truncateGitHash(process.env.REACT_APP_GIT_COMMIT!!)}
        />
        <CustomListItemText
          label="Commit Time"
          value={format(new Date(process.env.REACT_APP_GIT_DATE!!), "Pp")}
        />
      </Grid>
      <CustomDivider />
      <Typography variant="h6">Server Version</Typography>
      <ServerGitInfos serverInfo={serverInfo} />
      <CustomDivider />
      <Typography variant="h6">Solana Service Version</Typography>
      {typeof solanaServiceInfo === "object" ? (
        <Grid container>
          <CustomListItemText label="Tag" value={solanaServiceInfo.git.tag} />
          <CustomListItemText
            label="Commit"
            value={truncateGitHash(solanaServiceInfo.git.commit)}
          />
          <CustomListItemText
            label="Commit Time"
            value={format(new Date(solanaServiceInfo.git.date), "Pp")}
          />
        </Grid>
      ) : typeof solanaServiceInfo === "string" ? (
        "n/a"
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

function ServerGitInfos(props: { serverInfo?: ServerInfo }) {
  const serverInfo = props.serverInfo;
  if (!serverInfo) {
    return <CircularProgress />;
  }
  const time = serverInfo.git?.commit?.time;
  return (
    <Grid container>
      <CustomListItemText label="Tag" value={serverInfo.git?.branch || "n/a"} />
      <CustomListItemText
        label="Commit"
        value={truncateGitHash(serverInfo.git?.commit.id || "n/a")}
      />
      <CustomListItemText
        label="Commit Time"
        value={time ? format(new Date(time), "Pp") : "n/a"}
      />
    </Grid>
  );
}

function SolanaServiceInfos(props: { solanaServiceInfo: SolanaServiceInfo }) {
  const solanaServiceInfo = props.solanaServiceInfo;
  return (
    <>
      <RpcInfos rpcs={solanaServiceInfo.rpcs} />
      <Typography variant="h6">
        Quicknode RPC (used for QN-specific methods)
      </Typography>
      <CustomListItemText
        label="Quicknode RPC URL"
        value={solanaServiceInfo.qnRpcUrl}
      />
    </>
  );
}

function RpcInfos(props: { rpcs: RpcInfoDto[] }) {
  return (
    <>
      {props.rpcs.map((rpc, index) => (
        <span key={index}>
          <Typography variant="h6">
            {index === 0 ? "Default RPC" : `Fallback RPC #${index}`}
          </Typography>
          <RpcInfo rpc={rpc} />
          <CustomDivider />
        </span>
      ))}
    </>
  );
}

function RpcInfo(props: { rpc: RpcInfoDto }) {
  return (
    <>
      <CustomListItemText label="URL" value={props.rpc?.url} />
      <CustomListItemText label="Version" value={props.rpc?.version} />
      <CustomListItemText
        label="Health"
        value={props.rpc?.health && JSON.stringify(props.rpc?.health, null, 2)}
      />
    </>
  );
}

function CustomListItemText(props: { value: string; label: string }) {
  return (
    <Grid item xl={4} lg={6} xs={12}>
      <ListItemText primary={props.value} secondary={props.label} />
    </Grid>
  );
}
