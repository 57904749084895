import React from "react";
import { DashboardData } from "../../types/dashboardData";
import { GraphicalDashboardCard } from "../../components/dashboard/GraphicalDashboardCard";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { format } from "date-fns";

export function MemberGrowthCard(props: { data: DashboardData }) {
  const { data } = props;

  const options: ApexOptions = {
    chart: {
      toolbar: { show: false },
    },
    xaxis: {
      type: "datetime",
    },
    series: [
      {
        name: "Members",
        data: data.members.membersCountHistory.map((m) => ({
          x: format(Date.parse(m.date), "MM-dd-yyyy") + " UTC",
          y: m.count,
        })),
      },
    ],
    colors: ["#4832BB", "#FD6A1C", "#70707050"],
  };

  return (
    <GraphicalDashboardCard
      gridProps={{ xs: 12, md: 6 }}
      title={"Member Growth"}
      resource={"members"}
    >
      <Chart
        options={options}
        series={options.series}
        type="line"
        width={500}
      />
    </GraphicalDashboardCard>
  );
}
