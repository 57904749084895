import { useForm } from "@refinedev/react-hook-form";
import { HttpError } from "@refinedev/core";
import { useBranch } from "../../provider/BranchProvider";
import { FieldValues, FormProvider } from "react-hook-form";
import { Edit } from "@refinedev/mui";
import React from "react";
import { NewsForm } from "./NewsForm";
import { defaultNews, News } from "../../types/news";

export function EditNews() {
  const methods = useForm<News, HttpError>({
    defaultValues: defaultNews,
  });
  const branch = useBranch();
  const customHandleSubmit = (values: FieldValues) => {
    return methods.refineCore.onFinish({ ...values, branchId: branch?.id });
  };
  return (
    <Edit
      canDelete={true}
      saveButtonProps={{
        ...methods.saveButtonProps,
        onClick: methods.handleSubmit(customHandleSubmit),
      }}
    >
      <FormProvider {...methods}>
        <NewsForm />
      </FormProvider>
    </Edit>
  );
}
