import { HttpError } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { FormProvider } from "react-hook-form";
import {
  defaultMembershipType,
  MembershipType,
} from "../../types/membershipType";
import { useSaveFooterButton } from "../../components/form/useSaveFooterButton";
import { MembershipTypeForm } from "./MembershipTypeForm";

export default function EditMembershipType() {
  const methods = useForm<MembershipType, HttpError>({
    defaultValues: defaultMembershipType,
  });
  const editProps = useSaveFooterButton(methods);

  return (
    <Edit {...editProps}>
      <FormProvider {...methods}>
        <MembershipTypeForm />
      </FormProvider>
    </Edit>
  );
}
