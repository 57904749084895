import { useBranch } from "../../provider/BranchProvider";
import { CrudFilter, useList } from "@refinedev/core";
import { MembershipType } from "../../types/membershipType";

export function useMembershipTypes(
  includeBlockchain?: boolean
): MembershipType[] | undefined {
  const branch = useBranch();

  const excludeBlockchain: CrudFilter | null = includeBlockchain
    ? null
    : {
        field: "excludeBlockchain",
        operator: "eq",
        value: true,
      };

  const filters: CrudFilter[] = [
    {
      field: "branchId",
      operator: "eq",
      value: branch?.id,
    },
    ...(excludeBlockchain ? [excludeBlockchain] : []),
  ];

  return useList<MembershipType>({
    resource: "membership-types",
    filters,
    pagination: {
      current: 0,
      pageSize: 100, //TODO how high page size?
    },
  })?.data?.data as MembershipType[] | undefined;
}
