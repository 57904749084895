import QrCodeDownloadMenuItem from "components/table/QrCodeDownloadMenuItem";
import { useConfig } from "provider/ConfigProvider";
import QRCode from "react-qr-code";
import { Event } from "types/event";
import React from "react";
import { QrCodeButton } from "../../components/table/QrCodeButton";

export const QrCodeMenu = (props: { event: Event }) => {
  const { event } = props;
  const { staffAppUrl } = useConfig();
  return (
    <QrCodeButton>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: 8,
          marginBottom: 16,
        }}
      >
        <QRCode
          value={`${staffAppUrl}://event?code=${event.code}`}
          size={128}
        />
      </div>
      <QrCodeDownloadMenuItem
        url={`${staffAppUrl}://event?code=${event.code}`}
        filename={`${event.slug}-code.png`}
      />
    </QrCodeButton>
  );
};
