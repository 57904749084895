import React from "react";
import { DashboardContentValue } from "../../components/dashboard/DashboardContentValue";
import { DashboardData } from "../../types/dashboardData";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { PureNumberDashboardCard } from "../../components/dashboard/PureNumberDashboardCard";

export function CouponsCard(props: { data: DashboardData }) {
  const { data } = props;
  return (
    <PureNumberDashboardCard
      gridProps={{ xs: 12, md: data.coins ? 6 : 4 }}
      title={"Active Coupons"}
      icon={<ConfirmationNumberIcon fontSize={"large"} color={"primary"} />}
      resource={"coupon-types"}
    >
      <DashboardContentValue value={data.coupons.activeCoupons} />
    </PureNumberDashboardCard>
  );
}
