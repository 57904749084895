import { useLink } from "@refinedev/core";
import { PropsWithChildren } from "react";

export function MembershipTypeLink(
  props: {
    branchId: number;
    membershipTypeId: string | number;
  } & PropsWithChildren
) {
  const Link = useLink();

  return (
    <Link
      to={`/branches/${props.branchId}/membership-types/edit/${props.membershipTypeId}`}
    >
      {props.children}
    </Link>
  );
}
