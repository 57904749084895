import { useBranch } from "../../provider/BranchProvider";
import {
  type BaseRecord,
  type HttpError,
  useNavigation,
  useResource,
} from "@refinedev/core";
import {
  useDataGrid,
  UseDataGridProps,
  UseDataGridReturnType,
} from "@refinedev/mui";
import { GridRowParams } from "@mui/x-data-grid";

/**
 * Hook to use a data grid that is dependent on the branch. It also lets the user edit the record by clicking on the table row.
 */
export function useBranchDependentDataGrid<
  TQueryFnData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TSearchVariables = unknown,
  TData extends BaseRecord = TQueryFnData
>(
  params: UseDataGridProps<TQueryFnData, TError, TSearchVariables, TData>
): UseDataGridReturnType<TData, TError, TSearchVariables> & {
  dataGridProps: { onRowClick: (params: GridRowParams) => void };
} {
  const branch = useBranch();
  const resource = useResource();
  const { edit } = useNavigation();

  params.filters = {
    permanent: [{ field: "branchId", operator: "eq", value: branch?.id }],
  };
  params.editable = true;

  function editRow(params: GridRowParams) {
    edit(resource.resource?.name || "", params.row.id);
  }

  const result = useDataGrid<TQueryFnData, TError, TSearchVariables, TData>(
    params
  );
  return {
    ...result,
    dataGridProps: { ...result.dataGridProps, onRowClick: editRow },
  };
}
