import { BaseRecord, HttpError, RedirectAction } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";
import { UseFormReturnType } from "@refinedev/react-hook-form/src/useForm";
import { UseFormProps } from "@refinedev/react-hook-form/dist/useForm";
import { FieldPath } from "react-hook-form/dist/types/path";
import {
  tryFormatErrorUsingCode,
  tryRetrieveValidationErrors,
} from "../../utils/rest/errorHandling";

export type AdditionalFormProps = {
  resource?: string;
  redirect?: RedirectAction;
};

export const useDefaultForm = <
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables extends FieldValues = FieldValues,
  TContext extends object = object
>(
  props: UseFormProps<TData, TError, TVariables, TContext> &
    AdditionalFormProps = {}
): UseFormReturnType<TData, TError, TVariables, TContext> => {
  const form = useForm({
    ...props,
    refineCoreProps: {
      ...props.refineCoreProps,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onMutationError: (error, variables, context) => {
        console.log("error", error);
        const validationErrors = tryRetrieveValidationErrors(error);
        const fieldErrors = validationErrors?.fieldErrors || [];
        fieldErrors.forEach((e) => {
          const message = tryFormatErrorUsingCode(e) || e.defaultMessage;
          form.setError(e.field as FieldPath<TVariables>, { message });
        });
      },
      ...(props.resource !== undefined ? { resource: props.resource } : {}),
      ...(props.redirect !== undefined ? { redirect: props.redirect } : {}),
    },
  });
  return form;
};
