import { EventDate } from "types/event";
import { dateWithoutTimezone } from "./dateUtils";

export const getPublicEventUrl = (landingPageUrl: string, publicId: string) => {
  return `${landingPageUrl}/api/public/events/${publicId}`;
};

export const isEventPublished = (publishAt: string) =>
  publishAt && new Date(publishAt) <= new Date();

export const isEventScheduled = (publishAt: string) =>
  publishAt && new Date(publishAt) > new Date();

export const getUtcEventDate = (eventDate: EventDate): EventDate => {
  return {
    fromDate: dateWithoutTimezone(eventDate.fromDate),
    toDate: dateWithoutTimezone(eventDate.toDate),
    timezone: eventDate.timezone,
  };
};
