import { ImageDto } from "./image";
import { propertiesOf } from "../utils/propertyOf";

export const COUPON_ASPECT_RATIO = 2.0;

export interface Coupon {
  id: string;
  name: string;
  image: ImageDto | null;
  externalUrl: string | null;
  validUntil: string | null;
}

export const ofCoupon = propertiesOf<Coupon>();

export const defaultCoupon: Coupon = {
  id: "",
  name: "",
  externalUrl: "",
  image: null,
  validUntil: "",
};
