import { Box, useTheme } from "@mui/material";
import DeleteMenuItem from "components/table/DeleteMenuItem";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import { Event } from "types/event";

export const EventMenu = (props: { event: Event }) => {
  const { event } = props;
  const theme = useTheme();

  return (
    <Box position="absolute" top={theme.spacing(1)} right={theme.spacing(1)}>
      <MoreActionsButton>
        <DeleteMenuItem resource="events" id={event.id} />
      </MoreActionsButton>
    </Box>
  );
};
