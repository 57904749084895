import { DataGrid } from "@mui/x-data-grid";
import { useNavigation, useParsed } from "@refinedev/core";
import { useDataGrid } from "@refinedev/mui";
import { transactionCols } from "../../../tables/transactionCols";
import { Transaction } from "../../../types/transaction";
import { useBranch } from "../../../provider/BranchProvider";

export default function EventPayments() {
  const { id: eventId } = useParsed();
  const branch = useBranch();
  const { show } = useNavigation();

  const { dataGridProps } = useDataGrid<Transaction>({
    resource: "transactions",
    filters: {
      permanent: [
        { field: "branchId", operator: "eq", value: branch?.id },
        { field: "eventId", operator: "eq", value: eventId },
      ],
    },
  });

  return (
    <DataGrid
      {...dataGridProps}
      columns={transactionCols()}
      autoHeight
      isRowSelectable={() => false}
      onRowClick={(params) => {
        show("transactions", params.row.id);
      }}
    />
  );
}
