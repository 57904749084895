import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { useEffect } from "react";
import { HttpError } from "@refinedev/core";
import {
  formatValidationErrors,
  isValidationErrorResponse,
} from "./errorHandling";
import { LogoutOptions } from "@auth0/auth0-react";

export function useAxiosErrorHandling(
  axios: AxiosInstance,
  logout: (options?: LogoutOptions | undefined) => void
) {
  useEffect(() => {
    const id = registerAxiosInterceptor(axios, logout);
    return () => {
      unregisterAxiosInterceptor(axios, id);
    };
  }, [axios, logout]);
}

export function registerAxiosInterceptor(
  axios: AxiosInstance,
  logout: (options?: LogoutOptions | undefined) => void
): number {
  return axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      const status = error.response?.status || 500;

      let customError: HttpError = {
        ...error,
        message: error.response?.data as string,
        statusCode: status,
      };

      if (error.response && status === 401) {
        logout({
          logoutParams: { returnTo: window.location.origin },
        });
      } else if (error.response && isValidationErrorResponse(error.response)) {
        customError = {
          ...error,
          message: formatValidationErrors(error.response?.data),
          statusCode: status,
        };
      }

      return Promise.reject(customError);
    }
  );
}

export function unregisterAxiosInterceptor(axios: AxiosInstance, id: number) {
  axios.interceptors.response.eject(id);
}
