import { HttpError } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { FormProvider } from "react-hook-form";
import { defaultCouponType, CouponType } from "../../types/couponType";
import { useSaveFooterButton } from "../../components/form/useSaveFooterButton";
import { CouponTypeForm } from "./CouponTypeForm";

export default function EditCouponType() {
  const methods = useForm<CouponType, HttpError>({
    defaultValues: defaultCouponType,
  });
  const editProps = useSaveFooterButton(methods);

  return (
    <Edit {...editProps}>
      <FormProvider {...methods}>
        <CouponTypeForm />
      </FormProvider>
    </Edit>
  );
}
