import { GridColDef } from "@mui/x-data-grid";
import CoinAmount from "../components/CoinAmount";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import EditTicketTypeDialog from "../pages/events/form/EditTicketTypeDialog";
import { TicketType } from "../types/ticketType";
import CreateTicketDialog from "../pages/events/form/CreateTicketDialog";
import { ImageColumn } from "../components/table/ImageColumn";
import React from "react";

export const ticketTypeCols = (
  updateForm: (ticketType: TicketType, index: number) => void
): GridColDef<TicketType & { index: number }>[] => [
  {
    field: "id",
    headerName: "Id",
  },
  {
    field: "image",
    headerName: "Image",
    renderCell: function render({ row }) {
      if (!row.image) {
        return null;
      }
      return (
        <ImageColumn
          imageUrl={row.image?.url}
          borderRadius={"10%"}
          maxWidth={8}
        />
      );
    },
    minWidth: 120,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "supply",
    headerName: "Available quantity",
    flex: 1,
    renderCell: function render({ row }) {
      return row.supply || "-";
    },
  },
  {
    field: "price",
    headerName: "Price",
    flex: 1,
    filterable: false,
    renderCell: function render({ row }) {
      return row.price ? <CoinAmount amount={row.price} /> : "-";
    },
  },
  {
    field: "salesStart",
    headerName: "Start of sale",
    flex: 1,
    renderCell: function render({ row }) {
      return row.salesStart ? (
        <span>{new Date(row.salesStart).toLocaleString()}</span>
      ) : (
        "-"
      );
    },
  },
  {
    field: "more",
    headerName: "",
    renderCell: function render({ row }) {
      return (
        <MoreActionsButton>
          <EditTicketTypeDialog
            ticketTypeId={row.id}
            updateForm={(ticketType) => updateForm(ticketType, row.index)}
          />
          <CreateTicketDialog ticketTypeId={row.id} />
        </MoreActionsButton>
      );
    },
    align: "center",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    disableExport: true,
    disableColumnMenu: true,
  },
];
