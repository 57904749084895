import { Metaplex } from "@metaplex-foundation/js";
import { Connection } from "@solana/web3.js";
import { createContext, ReactNode, useContext } from "react";
import { useConfig } from "./ConfigProvider";

export interface Web3Utils {
  metaplex: Metaplex;
}

export const Web3Context = createContext<Web3Utils>({} as any);

export const Web3Provider = (props: { children: ReactNode }) => {
  const config = useConfig();
  const metaplex = Metaplex.make(new Connection(config.solanaRpcUrl));

  return (
    <Web3Context.Provider value={{ metaplex }}>
      {props.children}
    </Web3Context.Provider>
  );
};

export function useWeb3(): Web3Utils {
  return useContext(Web3Context);
}
