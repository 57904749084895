import React from "react";
import { Control } from "react-hook-form";
import FormTextField from "./FormTextField";

export function CurrencyAmountField(props: {
  name: string;
  control: Control;
  label: string;
  required?: boolean;
}) {
  const { name, label, required, control } = props;

  return (
    <FormTextField
      control={control}
      name={name}
      label={label}
      type="number"
      additionalRules={{
        min: 0,
      }}
      transform={{
        input: (value: number) => {
          // console.log("Transforming value for input", value);
          return value * 100;
        },
        output: (value: number) => {
          // console.log("Transforming value for output", value);
          return value / 100;
        },
      }}
      required={required}
    />
  );
}
