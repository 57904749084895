import { useBranchDependentDataGrid } from "../../utils/hooks/useBranchDependentDataGrid";
import { List } from "@refinedev/mui";
import { DataGrid } from "@mui/x-data-grid";
import { transactionCols } from "../../tables/transactionCols";
import { Transaction } from "../../types/transaction";
import { useNavigation } from "@refinedev/core";
import { Typography } from "@mui/material";

export default function TransactionList() {
  const { dataGridProps } = useBranchDependentDataGrid<Transaction>({});

  const { show } = useNavigation();

  return (
    <List>
      <Typography variant={"subtitle1"} sx={{ mb: 3, mt: -3 }}>
        All transactions of your branch.
      </Typography>
      <DataGrid
        {...dataGridProps}
        columns={transactionCols()}
        autoHeight
        onRowClick={(params) => {
          show("transactions", params.row.id);
        }}
        getRowId={(row) => row.createdAt}
      />
    </List>
  );
}
