import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  RefineButtonClassNames,
  RefineButtonTestIds,
} from "@refinedev/ui-types";
import React, { useEffect, useState } from "react";
import {
  CreateBlockchainImport,
  defaultCreateBlockchainImport,
  ofCreateBlockChainImport,
} from "../../types/blockchain";
import { useForm } from "@refinedev/react-hook-form";
import { HttpError } from "@refinedev/core";
import { FormProvider } from "react-hook-form";
import { useBranch } from "../../provider/BranchProvider";
import FormTextField from "../../components/form/FormTextField";

export default function ImportBlockchain(props: {
  creatorAddress?: string;
  fetchData: () => Promise<void>;
}) {
  const methods = useForm<CreateBlockchainImport, HttpError>({
    defaultValues: defaultCreateBlockchainImport(props.creatorAddress),
  });

  const branch = useBranch();

  const {
    handleSubmit,
    refineCore: { onFinish },
  } = methods;

  const [pending, setPending] = useState(false);

  const handleImport = async () => {
    setPending(true);
    await handleSubmit(async (values) => {
      await onFinish({
        ...values,
        branchId: branch?.id,
      });
    })();
    setPending(false);
    props.fetchData();
  };

  useEffect(() => {
    if (props.creatorAddress) {
      methods.setValue("creatorAddress", props.creatorAddress);
    }
  }, [props.creatorAddress, methods]);

  var disabled = false;
  if (props.creatorAddress != null) {
    disabled = true;
  }

  return (
    <FormProvider {...methods}>
      <Grid
        container
        display="flex"
        spacing={5}
        alignItems="flex-end"
        justifyContent="flex-start"
        marginBottom={1}
      >
        <Grid item xs={12} md={8} xl={4}>
          <FormTextField
            name={ofCreateBlockChainImport("creatorAddress")}
            label={"Creator Address"}
            control={methods.control}
            required
            disabled={disabled}
            fullWidth
          />
        </Grid>
        <Grid item>
          <LoadingButton
            variant="contained"
            data-testid={RefineButtonTestIds.ImportButton}
            className={RefineButtonClassNames.ImportButton}
            onClick={handleImport}
            loading={pending}
            disabled={pending}
          >
            Import NFTs
          </LoadingButton>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
