import { useForm } from "@refinedev/react-hook-form";
import { Coin, defaultCoin } from "../../types/coin";
import { HttpError } from "@refinedev/core";
import { useBranch } from "../../provider/BranchProvider";
import { FieldValues, FormProvider } from "react-hook-form";
import { Create } from "@refinedev/mui";
import { CoinForm } from "./CoinForm";
import React from "react";

export function CreateCoin(props: {}) {
  const methods = useForm<Coin, HttpError>({
    defaultValues: defaultCoin,
  });
  const branch = useBranch();
  const customHandleSubmit = (values: FieldValues) => {
    return methods.refineCore.onFinish({ ...values, branchId: branch?.id });
  };
  return (
    <Create
      saveButtonProps={{
        ...methods.saveButtonProps,
        onClick: methods.handleSubmit(customHandleSubmit),
      }}
    >
      <FormProvider {...methods}>
        <CoinForm />
      </FormProvider>
    </Create>
  );
}
