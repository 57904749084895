import { List } from "@refinedev/mui";
import { Typography } from "@mui/material";

import React, { useCallback, useEffect } from "react";
import {
  BlockchainImport,
  defaultBlockchainImport,
  ofBlockchainImport,
} from "../../types/blockchain";
import { useForm } from "@refinedev/react-hook-form";
import { HttpError } from "@refinedev/core";
import ImportBlockchain from "./ImportBlockchain";
import { apiHost } from "../../utils/rest/restApi";
import { useAxios } from "../../provider/AxiosProvider";
import { useBranch } from "../../provider/BranchProvider";

export default function BlockchainList() {
  const methods = useForm<BlockchainImport, HttpError>({
    defaultValues: defaultBlockchainImport,
  });

  const branch = useBranch();
  const axios = useAxios();

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(
        apiHost + "/blockchain-import?branchId=" + branch?.id
      );

      const { createdAt, finishedAt, creatorAddress } = response.data;

      methods.setValue(
        ofBlockchainImport("createdAt"),
        new Date(createdAt).toLocaleString()
      );
      methods.setValue(
        ofBlockchainImport("finishedAt"),
        new Date(finishedAt).toLocaleString()
      );
      methods.setValue(ofBlockchainImport("creatorAddress"), creatorAddress);
      watchValues();
    } catch (error) {
      console.error("Failed to sync:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let lastSync = methods.watch(ofBlockchainImport("createdAt"));
  let finishedAt = methods.watch(ofBlockchainImport("finishedAt"));
  let creatorAddress = methods.watch(ofBlockchainImport("creatorAddress"));

  const watchValues = () => {
    lastSync = methods.watch(ofBlockchainImport("createdAt"));
    finishedAt = methods.watch(ofBlockchainImport("finishedAt"));
    creatorAddress = methods.watch(ofBlockchainImport("creatorAddress"));
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <List>
      <Typography variant={"subtitle1"} sx={{ mb: 3, mt: -3 }}>
        Import blockchain-nfts of your members here.
      </Typography>

      {creatorAddress && lastSync && finishedAt && (
        <Typography variant={"body1"} sx={{ mb: 3, mt: 3 }}>
          Last synced {creatorAddress} at: {lastSync}, finished at {finishedAt}
        </Typography>
      )}
      <ImportBlockchain creatorAddress={creatorAddress} fetchData={fetchData} />
    </List>
  );
}
