import { Grid, TablePagination, Typography } from "@mui/material";
import { CreateButton, List, useDataGrid } from "@refinedev/mui";
import { Event } from "types/event";
import EventCard, { EventCardSkeleton } from "./EventCard";
import EventFilter from "./EventFilter";
import { useBranch } from "../../provider/BranchProvider";

export default function EventList() {
  const branch = useBranch();
  const {
    tableQueryResult: { data, isLoading, isRefetching },
    current,
    setCurrent,
    pageSize,
    setPageSize,
    filters,
    setFilters,
  } = useDataGrid<Event>({
    filters: {
      permanent: [{ field: "branchId", operator: "eq", value: branch?.id }],
    },
    pagination: {
      pageSize: 12,
    },
  });

  return (
    <List
      headerButtons={branch ? <CreateButton>Create Event</CreateButton> : <></>}
    >
      <Typography variant={"subtitle1"} sx={{ mb: 3, mt: -3 }}>
        Published events are displayed to your members. They can see the event
        information & buy tickets.
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <EventFilter setFilters={setFilters} filters={filters} />
        </Grid>
        <Grid container item spacing={3}>
          {!isLoading && !isRefetching ? (
            data && data.data.length > 0 ? (
              data.data.map((event: Event, i) => (
                <Grid item key={i} xs={12} sm={6} lg={4} xl={3}>
                  <EventCard event={event} />
                </Grid>
              ))
            ) : (
              <Grid
                item
                width="100%"
                display="flex"
                justifyContent={"center"}
                marginTop={3}
              >
                No events found for this branch
              </Grid>
            )
          ) : (
            [...Array(12)].map((_, i) => (
              <Grid item key={i} xs={12} sm={6} lg={4} xl={3}>
                <EventCardSkeleton />
              </Grid>
            ))
          )}
        </Grid>
        <Grid item marginLeft={"auto"}>
          <TablePagination
            component="div"
            count={data?.total ?? 0}
            page={current - 1}
            rowsPerPageOptions={[12, 24, 48, 96]}
            rowsPerPage={pageSize}
            onPageChange={(_, value: number) => setCurrent(value + 1)}
            onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value))}
          />
        </Grid>
      </Grid>
    </List>
  );
}
