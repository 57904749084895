import { Box } from "@mui/material";
import { HttpError } from "@refinedev/core";
import { Create, SaveButton } from "@refinedev/mui";
import FormTextField from "components/form/FormTextField";
import React from "react";
import { FormProvider } from "react-hook-form";
import { Branch, defaultBranch, ofBranch } from "types/branch";
import FormCheckboxField from "../../components/form/FormCheckboxField";
import { useDefaultForm } from "../../components/form/useDefaultForm";

export default function CreateBranch() {
  const methods = useDefaultForm<Branch, HttpError>({
    defaultValues: defaultBranch,
  });

  return (
    <Create footerButtons={() => <SaveButton {...methods.saveButtonProps} />}>
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <FormTextField
            name={ofBranch("name")}
            control={methods.control}
            label={"Name"}
            required
          />
          <FormTextField
            name={ofBranch("abbreviation")}
            control={methods.control}
            label={"Abbreviation"}
            required
            helperText={
              "Identificator of this branch. Cannot be changed later."
            }
          />
          <FormTextField
            name={ofBranch("contact")}
            control={methods.control}
            label={"Contact email"}
            additionalRules={{
              pattern: {
                value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                message: "Invalid Email",
              },
            }}
          />
          <FormCheckboxField
            name={ofBranch("personalDataRequired")}
            control={methods.control}
            label={"Require personal data?"}
            helperText="Require personal data (name, email and company) of members? Cannot be changed later."
          />
          <FormCheckboxField
            name={ofBranch("useSolana")}
            control={methods.control}
            label={"Store memberships on Solana Blockchain"}
            helperText="Cannot be changed later."
          />
        </Box>
      </FormProvider>
    </Create>
  );
}
