import RuleIcon from "@mui/icons-material/Rule";
import { GridColDef } from "@mui/x-data-grid";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import ShowMenuItem from "components/table/ShowMenuItem";
import { CouponType, ofCouponType } from "../types/couponType";
import { ImageColumn } from "../components/table/ImageColumn";
import DeleteMenuItem from "../components/table/DeleteMenuItem";

export const couponTypeCols = (): GridColDef<CouponType>[] => [
  {
    field: ofCouponType("id"),
    headerName: "Id",
  },
  {
    field: ofCouponType("image"),
    headerName: "Image",
    renderCell: function render({ row }) {
      return (
        <ImageColumn
          imageUrl={row.image?.url}
          borderRadius={"10%"}
          maxWidth={8}
        />
      );
    },
  },
  {
    field: ofCouponType("name"),
    headerName: "Name",
    flex: 1,
  },
  {
    field: ofCouponType("externalUrl"),
    headerName: "External link",
    flex: 1,
  },
  {
    field: ofCouponType("validUntil"),
    headerName: "Valid until",
    renderCell: function render({ row }) {
      return row.validUntil ? new Date(row.validUntil).toLocaleString() : "-";
    },
    flex: 1,
    minWidth: 100,
  },
  {
    field: "more",
    headerName: "",
    renderCell: function render({ row }) {
      return (
        <MoreActionsButton>
          <ShowMenuItem
            id={row.id}
            resource={"coupon-types"}
            label="Airdrops"
            startIcon={<RuleIcon />}
          />
          <DeleteMenuItem resource="coupon-types" id={row.id} />
        </MoreActionsButton>
      );
    },
    align: "center",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    disableExport: true,
    disableColumnMenu: true,
  },
];
