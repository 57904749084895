import { Button, Dialog, DialogContent } from "@mui/material";
import { useModal } from "@refinedev/core";
import { useState } from "react";
import { useAxios } from "../../../provider/AxiosProvider";
import { useFormContext } from "react-hook-form";

export default function EventPreview(props: { publicEventId: string }) {
  const axios = useAxios();
  const { visible, show, close } = useModal();
  const [htmlContent, setHtmlContent] = useState("");
  const formMethods = useFormContext();

  const fetchHtml = async () => {
    try {
      // Hier wird die POST-Anfrage an das Backend gesendet
      const response = await axios.post(
        "/api/events/preview",
        formMethods.getValues()
      );
      const result = await response.data;
      setHtmlContent(result);
      show();
    } catch (error) {
      console.error("Error fetching HTML:", error);
    }
  };

  return (
    <div>
      <Button variant="outlined" onClick={fetchHtml}>
        Preview Event
      </Button>
      <Dialog open={visible} onClose={close} fullWidth maxWidth="md">
        <DialogContent>
          {htmlContent && (
            <iframe
              srcDoc={htmlContent}
              title="Preview Event"
              style={{ width: "100%", height: "500px", border: "none" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
