import {
  FormControl,
  FormHelperText,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { useMembershipTypes } from "../../utils/hooks/useMembershipTypes";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(1),
    border: "1px solid transparent",
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function SelectMembership(props: {
  name: string;
  control: Control;
  includeBlockchain?: boolean;
}) {
  const { control, name } = props;
  const membershipTypes = useMembershipTypes(props.includeBlockchain);
  const sortedMembershipTypes = membershipTypes?.sort((m1, m2) =>
    m1.name.localeCompare(m2.name)
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: true,
          message: "This is required",
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth margin="normal" sx={{ marginBottom: 4 }}>
          <StyledToggleButtonGroup
            exclusive
            value={field.value}
            onChange={(_, value) => field.onChange(value)}
          >
            {sortedMembershipTypes?.map((membership, i) => (
              <ToggleButton
                value={membership.id}
                key={i}
                sx={{ display: "flex", flexDirection: "column", gap: 1 }}
              >
                {membership.imageUrl && (
                  <img
                    width="100px"
                    src={membership.imageUrl}
                    alt="membership"
                  />
                )}
                <Typography variant="body2">{membership.name}</Typography>
                <Typography variant="caption">{membership.symbol}</Typography>
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
          <FormHelperText error>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
