import { IdToken, LogoutOptions, User } from "@auth0/auth0-react";
import { AuthBindings } from "@refinedev/core";
import { AxiosInstance } from "axios";

export const authProvider = (
  axios: AxiosInstance,
  user: User | undefined,
  logout: (options?: LogoutOptions | undefined) => void,
  getIdTokenClaims: () => Promise<IdToken | undefined>
): AuthBindings => ({
  login: async () => {
    return {
      success: true,
    };
  },
  logout: async () => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
    return {
      success: true,
    };
  },
  onError: async (error) => {
    // console.error(error);
    return { error };
  },
  check: async () => {
    try {
      const token = await getIdTokenClaims();
      if (token) {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${token.__raw}`,
        };
        return {
          authenticated: true,
        };
      } else {
        return {
          authenticated: false,
          error: new Error("Token not found"),
          redirectTo: "/login",
          logout: true,
        };
      }
    } catch (error: any) {
      return {
        authenticated: false,
        error: new Error(error),
        redirectTo: "/login",
        logout: true,
      };
    }
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    if (user) {
      return {
        ...user,
        avatar: user.picture,
      };
    }
    return null;
  },
});
