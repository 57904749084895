import { ofNews } from "../types/news";
import { GridColDef } from "@mui/x-data-grid";
import { parseISO } from "date-fns";

export const newsCols: GridColDef[] = [
  {
    field: ofNews("title"),
    headerName: "Title",
    flex: 1,
  },
  {
    field: ofNews("publishAt"),
    headerName: "Publish Date",
    flex: 1,
    valueFormatter: (value) => parseISO(value as string).toLocaleString(),
  },
];
