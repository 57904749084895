import { propertiesOf } from "../utils/propertyOf";

export interface CoinTransfer {
  targetAddress: string;
  coinId: number;
  amount: number;
}

export const defaultCoinTransfer: CoinTransfer = {
  targetAddress: "",
  coinId: 1,
  amount: 1,
};

export const ofCoinTransfer = propertiesOf<CoinTransfer>();
