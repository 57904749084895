import { DashboardContentValue } from "../../components/dashboard/DashboardContentValue";
import { DashboardData } from "../../types/dashboardData";
import TollIcon from "@mui/icons-material/Toll";
import React from "react";
import { PureNumberDashboardCard } from "../../components/dashboard/PureNumberDashboardCard";

export function CoinsCard(props: { data: DashboardData }) {
  const { data } = props;
  if (!data.coins) {
    return null;
  }
  return (
    <PureNumberDashboardCard
      gridProps={{ xs: 12, md: 6 }}
      title={"Coin in circulation"}
      icon={<TollIcon fontSize={"large"} color={"primary"} />}
      resource={"transactions"}
    >
      <DashboardContentValue value={data.coins.ownedCoins / 100} />
    </PureNumberDashboardCard>
  );
}
