import { CrudFilters } from "@refinedev/core";
import { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";

export default function MemberFilter(props: {
  filters: CrudFilters;
  setFilters: ((filters: CrudFilters) => void) &
    ((setter: (prevFilters: CrudFilters) => CrudFilters) => void);
}) {
  const { setFilters } = props;

  const [search, setSearch] = useState("");

  const resetFilters = () => {
    setSearch("");
  };

  return (
    <Grid
      container
      display="flex"
      spacing={2}
      alignItems="flex-end"
      justifyContent="flex-start"
      marginBottom={1}
    >
      <Grid item>
        <TextField
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setFilters((filters) => [
              {
                field: "search",
                value: e.target.value,
                operator: "eq",
              },
              ...filters,
            ]);
          }}
          label="Search"
          variant="standard"
        />
      </Grid>
      <Grid item>
        <Button onClick={resetFilters}>Reset Filter</Button>
      </Grid>
    </Grid>
  );
}
