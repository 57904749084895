import { DataGrid } from "@mui/x-data-grid";
import { List } from "@refinedev/mui";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ticketTypeCols } from "../../../../tables/ticketTypeCols";
import { Event, ofEvent } from "types/event";
import { TicketType } from "../../../../types/ticketType";
import CreateTicketTypeDialog from "../CreateTicketTypeDialog";

export default function TicketTypesPage() {
  const { control } = useFormContext<Event>();
  const { fields, append, update } = useFieldArray({
    control,
    name: ofEvent("ticketTypes"),
    keyName: "key",
  });

  return (
    <List
      title="Tickets"
      breadcrumb={<></>}
      headerButtons={
        <CreateTicketTypeDialog
          setFormValue={(ticket: TicketType) => append(ticket)}
        />
      }
    >
      <DataGrid
        rows={fields.map((ticket, index) => ({ ...ticket, index: index }))}
        columns={ticketTypeCols((ticket, index) => update(index, ticket))}
        autoHeight
        sx={{
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        hideFooterPagination
      />
    </List>
  );
}
