import { GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { Membership, ofMembership } from "../types/membership";

export const membershipCols: GridColDef<Membership>[] = [
  {
    field: ofMembership("serial"),
    headerName: "Serial",
  },
  {
    field: ofMembership("image"),
    headerName: "Image",
    renderCell: function render({ row }) {
      const imageUrl = row.image?.url ?? row.imageUrl;
      return (
        imageUrl && (
          <Box
            component="img"
            src={imageUrl}
            sx={(theme) => ({
              verticalAlign: "middle",
              maxWidth: theme.spacing(4),
            })}
          />
        )
      );
    },
    minWidth: 120,
    sortable: false,
  },
  {
    field: ofMembership("name"),
    headerName: "Type Name",
    flex: 1,
  },
  {
    field: ofMembership("symbol"),
    headerName: "Type Symbol",
    flex: 1,
  },
];
