import { Box, Link, Tooltip } from "@mui/material";
import ShareColumn from "./table/ShareColumn";
import { useConfig } from "provider/ConfigProvider";
import { PublicKey } from "@solana/web3.js";

export function publicKeyToUrl(pubKey: string, cluster: string): string {
  return `https://solscan.io/account/${pubKey}?cluster=${cluster}`;
}

export function txKeyToUrl(pubKey: string, cluster: string): string {
  return `https://solscan.io/tx/${pubKey}?cluster=${cluster}`;
}

export function shortenPublicKey(publicKey: string | undefined): string {
  if (!publicKey) {
    return "unknown";
  }
  if (publicKey.length <= 8) {
    return publicKey;
  }
  return (
    publicKey.substring(0, 4) +
    "..." +
    publicKey.substring(publicKey.length - 4)
  );
}

export function PublicKeyLink(props: {
  pubKey: string | PublicKey;
  linkToSolanaExplorer?: boolean;
}) {
  const address =
    typeof props.pubKey === "string" ? props.pubKey : props.pubKey.toBase58();
  const { solanaCluster } = useConfig();
  const shortPubKey = shortenPublicKey(address);
  const content1 = (
    <Tooltip title={address} arrow>
      <Box sx={{ fontFamily: "monospace" }}>{shortPubKey}</Box>
    </Tooltip>
  );
  const content2 = props.linkToSolanaExplorer ? (
    <Link
      href={publicKeyToUrl(address, solanaCluster)}
      target="_blank"
      underline="hover"
    >
      {content1}
    </Link>
  ) : (
    content1
  );

  return <ShareColumn value={address} content={content2} />;
}
