import { Box, Typography } from "@mui/material";
import { HttpError, useParsed } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import FormTextField from "components/form/FormTextField";
import {
  Branch,
  BranchStyle,
  defaultBranch,
  ofBranch,
  ofBranchStyle,
} from "types/branch";
import FormImageField from "../../components/form/FormImageField";
import React from "react";
import { FormProvider } from "react-hook-form";
import { ContrastPreview } from "./ContrastPreview";

function branchStyleName(v: keyof BranchStyle) {
  return `${ofBranch("style")}.${ofBranchStyle(v)}`;
}

/**
 * Edit branch style
 *
 * The branch style is a nested resource of the branch.
 * So we're setting to form to use the "branches" resource.
 */
export default function EditBranchStyle() {
  const parsed = useParsed();
  const branchId = parsed.params?.branchId;

  const methods = useForm<Branch, HttpError>({
    defaultValues: defaultBranch,
    refineCoreProps: {
      resource: `branches`,
      id: branchId,
      action: "edit",
      redirect: false,
    },
  });

  const highlightColor = methods.watch("style.highlightColor");
  const backgroundColor = methods.watch("style.backgroundColor");

  return (
    <Edit
      goBack={null}
      headerButtons={() => null}
      saveButtonProps={methods.saveButtonProps}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <Typography variant="h6">Highlight Color</Typography>
          <FormTextField
            name={branchStyleName("highlightColor")}
            control={methods.control}
            required
            type="color"
            margin="none"
            inputProps={{
              disableUnderline: true,
              style: { minHeight: "5em", maxWidth: "5em" },
            }}
          />
          <ContrastPreview text="#fff" background={highlightColor} />

          <Typography variant="h6" sx={{ mt: 4 }}>
            Background Color
          </Typography>
          <FormTextField
            name={branchStyleName("backgroundColor")}
            control={methods.control}
            required
            type="color"
            inputProps={{
              disableUnderline: true,
              style: { minHeight: "5em", maxWidth: "5em" },
            }}
          />
          <ContrastPreview text="#fff" background={backgroundColor} />

          <Typography variant="h6" sx={{ mt: 4 }}>
            Logo
          </Typography>
          <FormImageField
            control={methods.control}
            name={branchStyleName("logoImage")}
            required
            aspect={1.0}
            format={" (1:1)"}
          />

          <Typography variant="h6" sx={{ mt: 4 }}>
            Background
          </Typography>
          <FormImageField
            control={methods.control}
            name={branchStyleName("backgroundImage")}
            required
          />
        </Box>
      </FormProvider>
    </Edit>
  );
}
