import { Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useParsed } from "@refinedev/core";
import { useDataGrid } from "@refinedev/mui";
import { couponRuleCols } from "tables/couponRuleCols";
import { CouponRule } from "../../../types/couponType";
import CreateCouponRuleDialog from "./CreateCouponRuleDialog";

export default function CouponRuleList() {
  const { id: couponTypeId } = useParsed();
  const { dataGridProps } = useDataGrid<CouponRule>({
    resource: "coupon-rules",
    queryOptions: { refetchOnMount: true, refetchOnWindowFocus: true },
    filters: {
      permanent: [
        { field: "couponTypeId", operator: "eq", value: couponTypeId },
      ],
    },
  });

  return (
    <Grid container direction={"column"} gap={3}>
      <Typography variant={"subtitle1"} sx={{ mb: -4 }}>
        Rules define who gets a coupon automatically based on owned
        membership-type.
      </Typography>
      <Grid item justifyContent={"flex-end"} display={"flex"}>
        <CreateCouponRuleDialog />
      </Grid>
      <Grid item>
        <DataGrid
          {...dataGridProps}
          columns={couponRuleCols}
          autoHeight
          sx={{
            ...dataGridProps.sx,
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
