import { IconButton, Menu } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import QrCodeIcon from "@mui/icons-material/QrCode";

export function QrCodeButton(props: PropsWithChildren) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <QrCodeIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {props.children}
      </Menu>
    </>
  );
}
