import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { CrudFilters, useExport } from "@refinedev/core";
import { ExportButton } from "@refinedev/mui";
import { format } from "date-fns";
import { useState } from "react";
import { ofRedemption, Redemption, RedemptionStatus } from "types/redemption";
import { useBranch } from "../../provider/BranchProvider";
import { useMembershipTypes } from "../../utils/hooks/useMembershipTypes";

export default function RedemptionFilter(props: {
  exportDisabled: boolean;
  filters: CrudFilters;
  setFilters: ((filters: CrudFilters) => void) &
    ((setter: (prevFilters: CrudFilters) => CrudFilters) => void);
}) {
  const { exportDisabled, filters, setFilters } = props;
  const branch = useBranch();
  const [membershipType, setMembershipType] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<Date>();

  const { triggerExport } = useExport<Redemption>({
    pageSize: 100,
    filters: filters,
    mapData: (item) => {
      return {
        serial: item.serial,
        lotId: item.lotId,
        includedCoinAmount: item.includedCoinAmount,
        membership: item.membershipType.name,
        status: RedemptionStatus[item.status],
        createdAt: new Date(item.createdAt).toLocaleString(),
        redemptionUrl: item.qrCodeUrl,
      };
    },
    exportOptions: {
      filename: `redemptions-${branch?.name.toLowerCase()}`,
    },
  });

  const membershipTypes = useMembershipTypes();

  const resetFilters = () => {
    setFilters([]);
    setMembershipType("");
    setStatus("");
    setCreatedAt(undefined);
  };

  if (!membershipTypes) return <></>;

  return (
    <Grid
      container
      display="flex"
      spacing={2}
      alignItems="flex-end"
      justifyContent="flex-start"
      marginBottom={1}
    >
      <Grid item>
        <FormControl fullWidth>
          <InputLabel>Membership Type</InputLabel>
          <Select
            label="Membership"
            variant="standard"
            value={membershipType}
            onChange={(e) => {
              setMembershipType(e.target.value);
              setFilters((filters) => [
                {
                  field: "membershipTypeId",
                  value: e.target.value,
                  operator: "eq",
                },
                ...filters,
              ]);
            }}
            sx={{ minWidth: 200 }}
          >
            <MenuItem value={""} key={""}>
              {"---"}
            </MenuItem>
            {membershipTypes?.map((membershipType, i) => (
              <MenuItem key={i} value={membershipType.id}>
                {membershipType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            variant="standard"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              setFilters((filters) => [
                {
                  field: ofRedemption("status"),
                  value: e.target.value,
                  operator: "eq",
                },
                ...filters,
              ]);
            }}
            sx={{ minWidth: 200 }}
          >
            <MenuItem value={""} key={""}>
              {"---"}
            </MenuItem>
            {Object.values(RedemptionStatus).map((status, i) => (
              <MenuItem key={i} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <DatePicker
          label={"Created at"}
          format="dd/MM/yyyy"
          // @ts-ignore
          value={createdAt || null}
          onChange={(date: Date | null) => {
            if (date) {
              setCreatedAt(date);
              setFilters((filters) => [
                {
                  field: ofRedemption("createdAt"),
                  value: format(date, "yyyy-MM-dd"),
                  operator: "eq",
                },
                ...filters,
              ]);
            }
          }}
          slotProps={{
            textField: {
              variant: "standard",
              inputProps: { readOnly: true },
              InputLabelProps: { shrink: true },
            },
          }}
        />
      </Grid>
      <Grid item>
        <Button onClick={resetFilters}>Reset Filter</Button>
      </Grid>
      <Grid item>
        <ExportButton
          onClick={triggerExport}
          variant="outlined"
          disabled={exportDisabled}
        />
      </Grid>
    </Grid>
  );
}
