import { propertiesOf } from "../utils/propertyOf";

export interface WalletDto {
  id: number;
  address: string;
  name: string | null;
  email: string | null;
  company: string | null;
  type: string | null;
}

export const ofWalletDto = propertiesOf<WalletDto>();

export const defaultWallet: WalletDto = {
  id: 0,
  address: "",
  name: "",
  email: "",
  company: "",
  type: "",
};
