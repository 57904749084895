import { Edit, useDataGrid } from "@refinedev/mui";
import { HttpError, useNavigation, useShow } from "@refinedev/core";
import { defaultMember, Member, ofMember } from "../../types/member";
import { FormProvider } from "react-hook-form";
import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { membershipCols } from "../../tables/membershipCols";
import { MemberForm } from "./MemberForm";
import { useDefaultForm } from "../../components/form/useDefaultForm";
import { useSaveFooterButton } from "../../components/form/useSaveFooterButton";
import { useBranch } from "../../provider/BranchProvider";
import { couponCols } from "../../tables/couponCols";
import { CoinBalanceForm } from "../coin/CoinBalanceForm";
import { ticketCols } from "../../tables/ticketCols";
import { transactionCols } from "../../tables/transactionCols";
import { Transaction } from "../../types/transaction";

export function ShowMember() {
  const { show } = useNavigation();
  const branch = useBranch();
  const methods = useDefaultForm<Member, HttpError>({
    defaultValues: defaultMember,
    refineCoreProps: {
      queryMeta: {
        additionalParams: {
          branchId: branch?.id,
        },
      },
    },
  });
  const editProps = useSaveFooterButton(methods);

  const memberships = methods.watch(ofMember("memberships"));
  const coupons = methods.watch(ofMember("coupons"));
  const tickets = methods.watch(ofMember("tickets"));

  useEffect(() => {
    methods.register("id");
  }, [methods]);

  const member = useShow<Member, HttpError>({
    meta: {
      additionalParams: {
        branchId: branch?.id,
      },
    },
  }).query.data?.data;

  const memberAddress = methods.getValues(ofMember("walletAddress"));
  const { dataGridProps } = useDataGrid<Transaction>({
    resource: "transactions",
    filters: {
      permanent: [
        { field: "branchId", operator: "eq", value: branch?.id },
        { field: "memberAddress", operator: "eq", value: memberAddress },
      ],
    },
  });

  if (!member) return <div>Loading;</div>;

  return (
    <Edit {...editProps}>
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <MemberForm />
        </Box>
      </FormProvider>

      {member.coinBalance != null && (
        <CoinBalanceForm coinBalance={member.coinBalance} />
      )}

      <Grid item md={6}>
        <Box display={"flex"} flexDirection={"column"} marginTop={4}>
          <Typography variant={"h6"}>Memberships</Typography>
          {memberships && (
            <DataGrid columns={membershipCols} autoHeight rows={memberships} />
          )}
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box display={"flex"} flexDirection={"column"} marginTop={4}>
          <Typography variant={"h6"}>Coupons</Typography>
          {coupons && (
            <DataGrid columns={couponCols()} autoHeight rows={coupons} />
          )}
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box display={"flex"} flexDirection={"column"} marginTop={4}>
          <Typography variant={"h6"}>Tickets</Typography>
          {tickets && (
            <DataGrid columns={ticketCols()} autoHeight rows={tickets} />
          )}
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box display={"flex"} flexDirection={"column"} marginTop={4}>
          <Typography variant={"h6"}>Transactions</Typography>
          {tickets && (
            <DataGrid
              {...dataGridProps}
              columns={transactionCols()}
              autoHeight
              isRowSelectable={() => false}
              onRowClick={(params) => {
                show("transactions", params.row.id);
              }}
            />
          )}
        </Box>
      </Grid>
    </Edit>
  );
}
