import { Box } from "@mui/material";
import React from "react";
import Color from "colorjs.io";

export function ContrastPreview(props: { text: string; background: string }) {
  const contrast = new Color(props.text).contrast(
    new Color(props.background),
    "WCAG21"
  );
  return (
    <>
      <ContrastInfo contrast={contrast} />
      <Box p={2} color={props.text} sx={{ background: props.background }}>
        Example Text
      </Box>
    </>
  );
}

function ContrastInfo(props: { contrast: number }) {
  const contrast = props.contrast;

  function label(): string {
    if (contrast < 3.0) return "🚫 too low!";
    if (contrast < 4.5) return "✅ A";
    if (contrast < 7.0) return "✅ AA";
    return "✅ AAA";
  }

  return (
    <p>
      Contrast ratio: {contrast.toFixed(2)} {label()}
    </p>
  );
}
